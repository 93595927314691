import { SelectionModel } from '@angular/cdk/collections';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnalysisDto } from 'src/app/models/analysis-model';
import { AlertService } from 'src/app/services/alert.service';
import { TextAnalysisService } from 'src/app/services/text-analysis.service';

@Component({
  selector: 'app-text-analysis',
  templateUrl: './text-analysis.component.html',
  styleUrls: ['./text-analysis.component.scss'],
})
export class TextAnalysisComponent implements OnInit, AfterViewInit {
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  dataSource: MatTableDataSource<AnalysisDto>;
  displayedColumns: string[] = ['rawText', 'title', 'delete'];
  selection: AnalysisDto;
  firstFormValid: boolean = false;
  analysisResult: AnalysisDto;
  analysisInProgress: AnalysisDto;
  showAnalysisDetails:boolean=true;

  customerAnalysisList$: Observable<AnalysisDto[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private textAnalysisService: TextAnalysisService,
    private alertService: AlertService
  ) {}


  ngOnInit(): void {


    this.adjustTableColumnsForResolution(window.innerWidth);
    this.firstFormGroup = this._formBuilder.group(
      {
        textArea: ['', Validators.required]
      },
      {
        // validators: [this.validateTransition()]
      }
    );
    this.secondFormGroup = this._formBuilder.group({
      url: [''],
      title: [''],
    });

    this.refreshAnalysisList();
  }

  private refreshAnalysisList() {
    this.textAnalysisService.getAnalysisList().subscribe((list) => {
      this.dataSource = new MatTableDataSource(list);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: AnalysisDto): string {
    if (row) {
      return row.pk.toString();
    }
    return '';
  }

  // validateTransition(): ValidatorFn {
  //   return (group: FormGroup): ValidationErrors | null => {
  //     return group.controls['textArea'].value || group.controls['pk'].value
  //       ? null
  //       : { missinginput: 'missinginput' };
  //   };
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustTableColumnsForResolution(window.innerWidth);
  }

  private adjustTableColumnsForResolution(innerWidth: number) {
    if (innerWidth < 1000) {
      this.displayedColumns = ['rawText', 'delete'];
    } else {
    }
  }

  analyze() {
      this.textAnalysisService
        .createNewTextAnalysis(
          '',
          this.firstFormGroup.controls['textArea'].value
        )
        .subscribe((analysis) => {
          this.analysisResult = analysis;
          this.refreshAnalysisDetails();
          this.stepper.next();
        });
    
  }

  save() {
    this.analysisResult.title = this.secondFormGroup.controls['title'].value;
    this.analysisResult.url = this.secondFormGroup.controls['url'].value;
    this.textAnalysisService
      .saveAnalysis(this.analysisResult)
      .subscribe((result) => {
        if (result.success) {
          this.alertService.success(result.message);
          this.refreshAnalysisList();
        } else {
          this.alertService.error(result.message);
        }
      });
  }

  selectRow(row: AnalysisDto) {
    this.selection = row;
    // this.firstFormGroup.controls['pk'].setValue(row.pk);
    // if (this.firstFormGroup.controls['pk'].value) {
      this.analysisResult = this.selection;
      this.refreshAnalysisDetails();
    // }
    // this.analyze();
    this.stepper.next();
  }

  isSelected(row: AnalysisDto) {
    return this.selection === row;
  }

  refreshAnalysisDetails() {
    this.secondFormGroup.controls['title'].setValue(this.analysisResult.title);
    this.secondFormGroup.controls['url'].setValue(this.analysisResult.url);
  }

  deleteAnalysis(row: AnalysisDto) {
    if (confirm('Are you sure you want to delete the analysis?')) {
      this.textAnalysisService.deleteAnalysis(row.pk).subscribe((result) => {
        if (result.success) {
          this.alertService.success(result.message);
          this.refreshAnalysisList();
          this.analysisResult = null;
        } else {
          this.alertService.error(result.message);
        }
        this.stepper.reset();
      });
    }
  }

  stepTransition(event: StepperSelectionEvent) {
    if (event.selectedIndex == 0) {
      this.refreshAnalysisList();
      this.firstFormGroup.reset();
    }
  }
}
