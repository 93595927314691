import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Lexi,
  MovieAnalysisResult,
  MovieSearchResult,
} from 'src/app/models/movie-model';
import { AnalysisHelperService } from 'src/app/services/analyze.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map, mergeMap, filter, tap } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { AlertService } from 'src/app/services/alert.service';
import { BugService } from 'src/app/services/bug.service';
import { KnownWordsListService } from 'src/app/services/known-words-list.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { OverlayService } from 'src/app/services/overlay.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Subtitle } from 'src/app/models/subtitle.model';
import { AnalysisDto, AnalysisLexiDto } from 'src/app/models/analysis-model';

@Component({
  selector: 'app-movie-analyis',
  templateUrl: './movie-analysis.component.html',
  styleUrls: ['./movie-analysis.component.css'],
  providers: [AnalysisHelperService],
})
export class AnalyzeComponent implements OnInit {
  constructor(
    private movieService: AnalysisHelperService,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private favoriteService: FavoriteService,
    private alertService: AlertService,
    private bugService: BugService,
    private knownWordService: KnownWordsListService,
    private overlayService:OverlayService
  ) {}

  imdbId: string;
  analysis: AnalysisDto;


  ngOnInit() {
    // const spinner:OverlayRef=this.overlayService.open({ hasBackdrop: true },SpinnerComponent);

    this.route.queryParams.subscribe((params) => {
      this.imdbId = params['imdbID'];
      // this.movieService.analyze(this.imdbId).pipe(tap(x=>this.overlayService.close(spinner)));
      this.movieService.startMovieAnalysis(this.imdbId).subscribe(analysis=>this.analysis=analysis);

    });
  }

}
