import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { SearchService } from './services/search.service';
import { SearchComponent } from './components/search/search.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { FavoriteService } from './services/favorite.service';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { XhrInterceptor } from './app.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BackendUrlInterceptor } from './app.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FocabMaterialModule } from './ui/material.module';
import { LearnComponent } from './components/learn/learn.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MovieSearchComponent } from './components/movie/movie-search/movie-search.component';
import { MovieService } from './services/movie.service';
import { MovieEpisodeDetailComponent } from './components/movie/movie-episode-detail/movie-episode-detail.component';
import { AnalyzeComponent } from './components/movie-analysis/movie-analysis.component';
import { IdiomDetectorComponent } from './components/idiom-detector/idiom-detector.component';
import { IdiomService } from './services/idiom.service';
import { LexiService } from './services/lexi.service';
import { PipeModule } from './pipe/pipe.module';
import { BasicAuthInterceptor } from './authentication/basic-auth.interceptor';
import { ErrorInterceptor } from './authentication/error.interceptor';
import { WithCredentialsInterceptor } from './authentication/with-credentials.interceptor';
import { AuthenticationService } from './authentication/authentication.service';
import { BugService } from './services/bug.service';
import { KnownWordsListComponent } from './components/known-words-list/known-words-list.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegisterComponent } from './components/register/register.component';
import { FlashcardComponent } from './components/flashcard/flashcard.component';
import { FlashcardService } from './services/flashcard.service';
import { TextAnalysisComponent } from './components/text-analysis/text-analysis.component';
import { TextAnalysisService } from './services/text-analysis.service';
import { AnalysisResultsComponent } from './components/analysis-results/analysis-results.component';
import { FlashcardCreatePopoverComponent } from './components/flashcard-create-popover/flashcard-create-popover.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateNewListPopupComponent } from './components/create-new-list-popup/create-new-list-popup.component';
import { IndefinitePronounPipe } from './pipe/indefinitePronoun.pipe';
import { EditFlashcardComponent } from './components/flashcard-edit/flashcard-edit.component';
import { FlashcardQuickCreateComponent } from './components/flashcard-quick-create/flashcard-quick-create.component';
import { AnalysisResultsDetailsComponent } from './components/analysis-results-details/analysis-results-details.component';
import { AnalysisHelperService } from './services/analyze.service';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { XsrfInterceptor } from './authentication/xsrf.interceptor';
import { EncountersComponent } from './components/encounters/encounters.component';
import { EncounterService } from './services/encounter.service';
import { ToastComponent } from './components/toast/toast.component';
import { FlashcardStudyComponent } from './components/flashcard-study/flashcard-study.component';
import { QuizService } from './services/quiz.service';

@NgModule({
    declarations: [
        AppComponent,
        SearchComponent,
        SearchResultComponent,
        HomeComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        LearnComponent,
        SpinnerComponent,
        MovieSearchComponent,
        MovieEpisodeDetailComponent,
        AnalysisResultsComponent,
        AnalyzeComponent,
        IdiomDetectorComponent,
        KnownWordsListComponent,
        ProfileComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegisterComponent,
        FlashcardComponent,
        TextAnalysisComponent,
        FlashcardCreatePopoverComponent,
        CreateNewListPopupComponent,
        EditFlashcardComponent,
        FlashcardQuickCreateComponent,
        AnalysisResultsDetailsComponent,
        ScrollToTopComponent,
        EncountersComponent,
        ToastComponent,
        FlashcardStudyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FocabMaterialModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        PipeModule,
        ReactiveFormsModule,
        NgbModule,
    ],
    providers: [
        AppService,
        { provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BackendUrlInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WithCredentialsInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
        SearchService,
        MovieService,
        IdiomService,
        FavoriteService,
        MovieService,
        AuthenticationService,
        BugService,
        LexiService,
        FlashcardService,
        TextAnalysisService,
        IndefinitePronounPipe,
        AnalysisHelperService,
        EncounterService,
        QuizService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
