
export interface ToastInfo {

  body: string;
  level: ToastLevel;
  autohide?: boolean;
}


export enum ToastLevel {
  success = "success", warning = "warning", error = "error"
}