import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MovieSearchResult,
  MovieInfo,
  EpisodeSearchResult,
  MovieAnalysisResult,
} from '../models/movie-model';
import { Subtitle } from '../models/subtitle.model';
import { AnalysisDto, AnalysisLexiDto } from '../models/analysis-model';
import { FlashcardComponent } from '../components/flashcard/flashcard.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AnalysisHelperService {
  private _url: string = '/analyzeMovie?imdbId=';
  private _subtitlesUrl: string = '/getFullSubtitles?imdbId=';

  constructor(private http: HttpClient, private dialog: MatDialog) {}

  startMovieAnalysis(imdbId: string): Observable<AnalysisDto> {
    return this.http.get<AnalysisDto>(this._url + imdbId, {});
  }

  // getFullSubtitles(imdbId: string): Observable<Subtitle[]> {

  //   return this.http.get<Subtitle[]>(this._subtitlesUrl + imdbId, { });
  // }




}
