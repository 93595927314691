import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longtext',
  pure: true,
})
export class LongTextPipe implements PipeTransform {
  constructor() {}

  transform(value: String, visibleLength:number): any {
    if(!visibleLength)visibleLength=30;

    if (!value || value.length<=visibleLength) {
      return value;
    }
    return value.substring(0,visibleLength)+'...';
  }
}
