import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LexiService } from 'src/app/services/lexi.service';
import { LexiDetails } from 'src/app/models/lexi-details';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'indefinitepronoun',
  pure: true,
})
export class IndefinitePronounPipe implements PipeTransform {
  constructor(private lexiService: LexiService) {}

  transform(value: string, backward?: boolean): any {
    if (typeof value === 'undefined' || value === null) {
      return value;
    }
    if(backward){
      return this.indefinitePronounBackward(value);
    }
    return this.indefinitePronounForward(value);
  }

  indefinitePronounForward(text: string): string {
    /* One's	@
Something	#
Someone	##
Somewhere	###
oneself	@@ */
    return text
      .toString()
      .replace('@@', 'oneself')
      .replace('@', "one's")
      .replace('###', 'somewhere')
      .replace('##', 'someone')
      .replace('#', 'something');
  }

  indefinitePronounBackward(text: string): string {

    return text
      .toString()
      .toLowerCase()
      .replace('oneself', '@@')
      .replace("one's", '@')
      .replace('somewhere', '###')
      .replace('someone', '##')
      .replace('something', '#');
  }
}
