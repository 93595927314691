<div class="d-flex flex-column h-100">
  
  <app-header></app-header>
  <div class="d-flex justify-content-center">

    <app-toast aria-live="polite" aria-atomic="true"></app-toast>
  </div>

  <br /><br />
  <div class="top-placeholder">
    <router-outlet></router-outlet>
  </div>

  <app-footer class="footer mt-auto py-3 vocpad-footer"></app-footer>

</div>