import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';
import { AnalyzeComponent } from './components/movie-analysis/movie-analysis.component';
import { HomeComponent } from './components/home/home.component';
import { IdiomDetectorComponent } from './components/idiom-detector/idiom-detector.component';
import { KnownWordsListComponent } from './components/known-words-list/known-words-list.component';
import { LearnComponent } from './components/learn/learn.component';
import { LoginComponent } from './components/login/login.component';
import { MovieSearchComponent } from './components/movie/movie-search/movie-search.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { FlashcardComponent } from './components/flashcard/flashcard.component';
import { TextAnalysisComponent } from './components/text-analysis/text-analysis.component';
import { FlashcardQuickCreateComponent } from './components/flashcard-quick-create/flashcard-quick-create.component';
import { EncountersComponent } from './components/encounters/encounters.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/quick-create' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'learn', component: LearnComponent, canActivate: [AuthGuard] },
  { path: 'quick-create', component: FlashcardQuickCreateComponent, canActivate: [AuthGuard] },
  { path: 'searchResults', component: SearchResultComponent, canActivate: [AuthGuard] },
  { path: 'movie-search', component: MovieSearchComponent, canActivate: [AuthGuard] },
  { path: 'idiomSearch', component: IdiomDetectorComponent, canActivate: [AuthGuard] },
  { path: 'analyze', component: AnalyzeComponent, canActivate: [AuthGuard] },
  { path: 'knownwords', component: KnownWordsListComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'resetPassword/:token', component: ResetPasswordComponent },
  { path: 'flashcards', component: FlashcardComponent },
  { path: 'textAnalysis', component: TextAnalysisComponent },
  { path: 'encounters', component: EncountersComponent }




];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
