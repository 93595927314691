import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FavoriteList, FlashcardModel } from 'src/app/models/flashcard.model';
import { GenericResponse } from 'src/app/models/generic-response';
import { ToastLevel } from 'src/app/models/toast-model';
import { FavoriteService } from 'src/app/services/favorite.service';
import { ToastService } from 'src/app/services/toast.service';
import { CreateNewListPopupComponent } from '../create-new-list-popup/create-new-list-popup.component';

@Component({
  selector: 'app-edit-flashcard',
  templateUrl: './flashcard-edit.component.html',
  styleUrls: ['./flashcard-edit.component.scss'],
})
export class EditFlashcardComponent implements OnInit {
  flashcard: FlashcardModel;
  example: string = '';
  lists: Observable<FavoriteList[]> = this.favoriteService.getFavoriteLists();
  createFlashcardGroup: UntypedFormGroup;
  createListGroup: UntypedFormGroup;

  constructor(
    private favoriteService: FavoriteService,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<EditFlashcardComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.flashcard = this.data.flashcard;
  }

  refreshCustomerLists() {
    this.lists = this.favoriteService.getFavoriteLists();
  }

  onListSelected(newValue: number) {
    this.flashcard.listId = newValue;
  }

  openCreateListDialog() {
    const dialogRef = this.dialog.open(CreateNewListPopupComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.refreshCustomerLists();
    });
  }

  updateFlashcard() {
    this.favoriteService.updateFlashcard(this.flashcard).subscribe(
      (result) => {
        if (result.success) {
          this.toastService.show('Flashcard updated', ToastLevel.success);
          this.dialogRef.close();
        } else {
          this.toastService.show(
            'Something went wrong in updating the flashcard',
            ToastLevel.error
          );
        }
      },
      (error) => {
        this.toastService.show(
          'Something went wrong in updating the flashcard: ' + error,
          ToastLevel.error
        );
      }
    );
  }

  deleteFlashcard() {
    if (confirm('Are you sure you want to delete the flashcard?')) {
      this.favoriteService
        .deleteFlashcard(this.flashcard)
        .subscribe((result) => {
          if (result.success) {
            this.toastService.show(result.message, ToastLevel.error);
            this.dialogRef.close();
          } else {
            this.toastService.show(result.message, ToastLevel.error);
          }
        });
    }
  }
}
