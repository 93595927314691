import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndefinitePronounPipe } from './indefinitePronoun.pipe';
import { LongTextPipe } from './longtext.pipe';
import { FlashcardFilterPipe } from './filter-flashcard.pipe';
import { LevelFilterPipe } from './filter-level.pipe';



@NgModule({
  declarations: [IndefinitePronounPipe, LongTextPipe,FlashcardFilterPipe,LevelFilterPipe],
  imports: [
    CommonModule
  ],
  exports: [IndefinitePronounPipe,LongTextPipe,FlashcardFilterPipe,LevelFilterPipe]
})
export class PipeModule { }
