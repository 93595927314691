import { Injectable, TemplateRef } from '@angular/core';
import { ToastInfo, ToastLevel } from '../models/toast-model';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];

  show(body: string, level: ToastLevel, autohide?: boolean) {
    this.toasts.push({ body, level, autohide });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
