<div class="d-flex justify-content-center">
    <div class="d-flex flex-column w-100 w-lg-50 mw ms-2 me-2">
        <div>
            <h2 mat-dialog-title class="ms-0">Edit Flashcard</h2>
        </div>
        <div mat-dialog-content>

            <form class="">
                <div class="form-floating mb-1">
                    <input class="form-control" id="floatingInput" [(ngModel)]="flashcard.phrase"
                        name="lexiText" tabindex="0">
                    <label class="" for="floatingInput"> Word / Phrase</label>
                </div>
                <div class="d-flex mb-1">
                    <select required (change)="onListSelected($event.target.value)" class="form-select me-1">
                        <option *ngFor="let list of lists | async" [value]="list.favoriteId" required name="list" [selected]="list.favoriteId == listSelected">
                            {{list.name}}
                        </option>
                    </select>
                    <button class="btn btn-secondary text-nowrap" type="button" (click)="openCreateListDialog()">
                        New List
                    </button>
                </div>
                <div id="listHelp" class="form-text">Select a list for the flashcard or create a new one.</div>
                <div class="form-floating mb-1">
                    <input class="form-control" id="exampleSentence" [(ngModel)]="flashcard.example" name="example">
                    <label class="" for="exampleSentence"> Example</label>
                </div>
                <div class="d-flex">
                        <button class="btn btn-primary w-100" (click)="updateFlashcard()" >Update Flashcard</button>
                        <button class="btn btn-danger w-100" (click)="deleteFlashcard()" >Delete Flashcard</button>
                </div>
            </form>
        </div>
    </div>
</div>
