import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { QuizRequest, QuizResponse } from '../models/quiz.model';
import { ToastInfo, ToastLevel } from '../models/toast-model';

@Injectable({ providedIn: 'root' })
export class QuizService {

  private generateQuizUrl: string = '/generateQuiz';

  constructor(private http: HttpClient) {
  }

  generateQuiz(quizRequest: QuizRequest):Observable<QuizResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let body = JSON.stringify(quizRequest)

    return this.http.post<QuizResponse>(this.generateQuizUrl, body, {
      headers,
    });
  }

}
