import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AnalysisDto,
  AnalysisLexiDto,
  AnalysisLineDto,
} from 'src/app/models/analysis-model';
import { ToastLevel } from 'src/app/models/toast-model';
import { AlertService } from 'src/app/services/alert.service';
import { BugService } from 'src/app/services/bug.service';
import { FlashcardService } from 'src/app/services/flashcard.service';
import { KnownWordsListService } from 'src/app/services/known-words-list.service';
import { SearchService } from 'src/app/services/search.service';
import { TextAnalysisService } from 'src/app/services/text-analysis.service';
import { ToastService } from 'src/app/services/toast.service';
import { FlashcardCreatePopoverComponent } from '../flashcard-create-popover/flashcard-create-popover.component';
import { FlashcardComponent } from '../flashcard/flashcard.component';

@Component({
  selector: 'app-analysis-results-details',
  templateUrl: './analysis-results-details.component.html',
  styleUrls: ['./analysis-results-details.component.scss'],
})
export class AnalysisResultsDetailsComponent implements OnInit {
  lexi: AnalysisLexiDto;
  lexiText: string;
  lexiInFlascards: boolean;
  lastListId: number;
  lines: AnalysisLineDto[];

  nearbyLinesCollapsed: boolean[] = [];
  nearbyButtonVisible: boolean[] = [];
  nearbyLines: Observable<AnalysisLineDto[]>[] = [];

  definitionMap: Map<string, string[]> = new Map<string, string[]>();
  exactDefinitions: Observable<string[]>;
  showAllDefinitions: boolean;

  pk: number;

  constructor(
    private toastService: ToastService,
    private bugService: BugService,
    private searchService: SearchService,
    private flashcardService: FlashcardService,
    private dialog: MatDialog,
    private knownWordService: KnownWordsListService,
    private snackBar: MatSnackBar,
    private analysisService: TextAnalysisService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.lexi = this.data?.lexi;
    this.lexiText = this.data?.lexiText;
    this.exactDefinitions = this.searchService.search(this.lexiText).pipe(
      tap((searchResultLists) => {
        searchResultLists
          .filter(
            (searchResult) =>
              searchResult.word.toUpperCase() !== this.lexiText.toUpperCase()
          )
          .forEach((searchResult) =>
            this.definitionMap.set(
              searchResult.word,
              searchResult.defs.map((def) => def.definition)
            )
          );
      }),
      map((searchResultLists) => {
        return [].concat.apply(
          searchResultLists
            .filter(
              (searchResult) =>
                searchResult.word.toUpperCase() ===
                this.lexiText.toUpperCase()
            )
            .map((searchResult) =>
              searchResult.defs.map((def) => def.definition)
            )
        );
      })
    );

    this.lexiInFlascards = this.data?.lexiInFlascards;
    this.pk = this.data?.pk;
    if (this.lexi) {
      this.nearbyLinesCollapsed = Array.from(
        Array(this.lexi.relatedLines.length)
      ).fill(true);
      this.nearbyButtonVisible = Array.from(
        Array(this.lexi.relatedLines.length)
      ).fill(false);

      for (let index = 0; index < this.lexi.relatedLines.length; index++) {
        this.nearbyLines[index] = this.analysisService.getAnalysisLines(
          this.pk,
          this.lexi.relatedLines[index].lineId
        );
      }
    }

    this.showAllDefinitions = true;
  }

  addToBug(item: AnalysisLexiDto, type: string) {
    console.log('Add to bugs: ' + item.lexiText);
    const searchResultObservable = this.bugService.addBug(item, type);
    searchResultObservable.subscribe((flag: boolean) => {
      this.toastService.show('Bug reported!',ToastLevel.success);
      console.log('Report bug result: ' + flag);
    });
  }
  openLexiDetailsDialog(lexi: AnalysisLexiDto) {
    const dialogRef = this.dialog.open(AnalysisResultsDetailsComponent, {
      height: '600px',
      width: '600px',
      data: { lexi: lexi },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  buildLineStructure(analysis: AnalysisDto): AnalysisLineDto[] {
    console.log('Lines initiated');
    let lines: AnalysisLineDto[] = analysis?.lineDetails;
    lines.forEach((line) => {
      line.lineIdioms = [];
      line.linePhrasals = [];
      line.lineSingleWords = [];
    });
    analysis.idioms?.forEach((idiom) => {
      idiom.relatedLines.forEach((relatedLine) =>
        lines[relatedLine.lineId].lineIdioms?.push(idiom.lexiText)
      );
    });
    analysis.phrasals?.forEach((phrasal) => {
      phrasal.relatedLines.forEach((relatedLine) => {
        lines[relatedLine.lineId].linePhrasals?.push(phrasal.lexiText);
      });
    });
    analysis.singleWords?.forEach((singleWord) => {
      singleWord.relatedLines.forEach((relatedLine) =>
        lines[relatedLine.lineId].lineSingleWords?.push(singleWord.lexiText)
      );
    });
    return lines;
  }

  addToKnownWords(text: string) {
    console.log('Add to known words: ' + text);
    const searchResultObservable = this.knownWordService.addKnownWord(text);
    searchResultObservable.subscribe((flag: boolean) => {
      if (flag) {
        this.snackBar.open('Known word added to the list', '', {
          panelClass: ['alert', 'alert-success'],
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.toastService.show('Already in the known words',ToastLevel.warning);
      }
    });
  }

  openFlashcardDialog(lexiDto: AnalysisLexiDto,lexiText:string) {
    const dialogRef = this.dialog.open(FlashcardCreatePopoverComponent, {
      data: {
        lexiText: lexiText,
        listId: this.lastListId,
        lexiDisabled: true,
        examples: lexiDto?.relatedLines
          .map((line) => line.lineText.replace(/(\r\n|\n|\r)/gm, ''))
          .join(' | '),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.lastListId = result;
    });
  }

  openFlashcardsListDialog(lexiText: string) {
    const dialogRef = this.dialog.open(FlashcardComponent, {
      data: { filterText: lexiText },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  toggleCollapse(lineNo: number) {
    this.nearbyLinesCollapsed[lineNo] = !this.nearbyLinesCollapsed[lineNo];
  }
}
