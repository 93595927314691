import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { Lexi } from '../models/movie-model';
import { GenericResponse } from '../models/generic-response';
import { FlashcardModel } from '../models/flashcard.model';

@Injectable()
export class FlashcardService {
  private _addFlashcardUrl: string = '/addFlashcard';

  constructor(private http: HttpClient) {}

  addFlashcard(phrase: string, example: string): Observable<GenericResponse> {
    let body: String = '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    body = body.concat('{"phrase":"', phrase, '","example":"', example, '"}');
    return this.http.post<GenericResponse>(this._addFlashcardUrl, body, {
      headers: headers,
    });
  }

  getFlashcardsByList():Observable<Map<String,FlashcardModel[]>>{
    return this.http.get<Map<String,FlashcardModel[]>>('/getFlashcardsByList',{});
  }

  getFlashcards():Observable<FlashcardModel[]>{
    return this.http.get<FlashcardModel[]>('/getFlashcards',{});
  }

  getLexisInFlashcards():Observable<String[]>{
    return this.http.get<String[]>('/getLexisInFlashcards',{});
  }
}
