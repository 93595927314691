<div class="d-flex justify-content-center">
    <div class="d-flex flex-column w-100 w-lg-50 mw ms-2 me-2">
        <div>
            <h2 mat-dialog-title class="ms-0">Update Your Profile</h2>
        </div>
        <div mat-dialog-content>

            <form class="" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                <div class="form-floating mb-1">
                    <input class="form-control" id="floatingInput" formControlName="fullname"
                        [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" tabindex="0">
                    <label class="" for="floatingInput"> Name </label>
                </div>
                <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                    <div *ngIf="f.fullname.errors.required">Name is required</div>
                </div>
                <div class="form-floating mb-1">
                    <select required formControlName="level" name="level" class="form-select me-1" id="floatingInput2">
                        <!-- <option value="" selected></option> -->
                        <option *ngFor="let level of levels" [value]="level.id">
                            {{level.text}}
                        </option>
                    </select>
                    <label class="" for="floatingInput2"> English Level </label>
                </div>
                Ignores most frequent {{levels[f.level.value].text}} words during analysis. (Reference: <a
                    href="http://www.wordfrequency.info">www.wordfrequency.info</a>)
                <div *ngIf="submitted && f.level.errors" class="invalid-feedback">
                    <div *ngIf="f.level.errors.required">Level is required</div>
                </div>
                <br />

                <div class="d-flex flex-row mb-3">

                    <ng-template #popContent>
                        <div class="d-flex flex-column mb-3">

                        <div class="form-floating mb-1">
                            <input class="form-control" id="chatGptApiKey" formControlName="chatGptApiKey" onfocus="this.value=''">
                            <label class="" for="chatGptApiKey"> ChatGPT API Key </label>
                        </div>
                        <div class="d-flex flex-row">
                        <button type="button" [disabled]="loading" class="btn btn-primary btn-sm m-1"
                            (click)="updateApiKey()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Save
                        </button>
                        <button type="button" [disabled]="loading" class="btn btn-light btn-sm m-1"
                            (click)="pop.close()">
                            Cancel
                        </button>
                    </div>
                    </div>

                    </ng-template>
                    <ng-template #popTitle>Update ChatGPT API Key</ng-template>
                    <button type="button" class="btn btn-outline-secondary" [ngbPopover]="popContent"
                        [popoverTitle]="popTitle" placement="bottom" [autoClose]="'outside'" #pop="ngbPopover">
                        Update ChatGPT API Key
                    </button>
                </div>
                <button [disabled]="loading" type="submit" class="btn btn-primary w-100">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Update
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>

            </form>
        </div>
    </div>