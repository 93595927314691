import { FlashcardRequest } from "./flashcard.model";

export class SyncFlashcardRequest {
  url: string
  body: String
  headers: string
  constructor(
    url: string,
    body: String,
    headers?: string) {
    this.url = url;
    this.body = body;
    this.headers = headers;
  }



}