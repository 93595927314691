<div *ngIf="tokenValidityResponse | async as validity">
    <div *ngIf="!(validity?.success);else tokenValid" class="alert alert-danger mt-3 mb-0">
        {{validity.message}}
    </div>

    <ng-template #tokenValid>
        <div class="col-md-6 offset-md-3 mt-5">
            <div class="alert alert-info">
                Please choose a password with at least 8 characters long and that includes at least one capital letter
                and a number.
            </div>
            <div class="card">
                <h4 class="card-header">Password Reset</h4>
                <div class="card-body">
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="password1">Password</label>
                            <input type="password" formControlName="password1" class="form-control"
                                [ngClass]="{ 'is-invalid': f.password1.touched && f.password1.errors }" />
                            <div *ngIf="f.password1.touched && f.password1.errors" class="invalid-feedback">
                                <div *ngIf="f.password1.errors.required">The field is required</div>
                                <div *ngIf="f.password1.errors.pattern">Min 8 chars, at least 1 capitals and 1 digit
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password2"> Confirm Password</label>
                            <input type="password" formControlName="password2" class="form-control"
                                [ngClass]="{ 'is-invalid': f.password2.touched && resetPasswordForm.hasError('notSame')}" />
                            <div *ngIf="f.password2.touched && resetPasswordForm.hasError('notSame')"
                                class="invalid-feedback">
                                <div *ngIf="resetPasswordForm.hasError('notSame')">The passwords do not match</div>
                            </div>
                        </div>
                        <button [disabled]="loading" class="btn btn-primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Change Password
                        </button>
                        <div *ngIf="response | async as res">
                            <div *ngIf="res?.success" class="alert alert-success mt-3 mb-0">{{res?.message}}</div>
                            <div *ngIf="!res?.success" class="alert alert-danger mt-3 mb-0">{{res?.message}}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-template>
</div>