import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { Lexi } from '../models/movie-model';
import { GenericResponse } from '../models/generic-response';
import { FlashcardModel } from '../models/flashcard.model';
import { EncounterModel } from '../models/encounter.model';

@Injectable()
export class EncounterService {
  private _getEncountersUrl: string = '/getEncounters';
  private _deleteEncounterUrl: string = '/deleteEncounter?id=';

  constructor(private http: HttpClient) {}

  // addFlashcard(phrase: string, example: string): Observable<GenericResponse> {
  //   let body: String = '';
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   });

  //   body = body.concat('{"phrase":"', phrase, '","example":"', example, '"}');
  //   return this.http.post<GenericResponse>(this._addFlashcardUrl, body, {
  //     headers: headers,
  //   });
  // }

  getEncounters():Observable<EncounterModel[]>{
    return this.http.get<EncounterModel[]>('/getEncounters')

  }

  deleteEncounters(encounter: number): Observable<GenericResponse> {
    return this.http.delete<GenericResponse>(this._deleteEncounterUrl+encounter, {});
  }
}
