<mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="stepTransition($event)">
    <mat-step state="step1" >
        <ngb-accordion [closeOthers]="true" activeIds="static-1">
            <ngb-panel id="static-1" title="">
                <ng-template ngbPanelTitle>
                    <span><b>Create new analysis</b></span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="p-2 d-flex flex-column">
                        <form class="w-100 p-2 d-flex flex-wrap" [formGroup]="firstFormGroup">
    
                            <ng-template matStepLabel>INPUT</ng-template>
                            <div class="form-floating mb-1 w-100 p-2 ">
                                <textarea  class="form-control h-100" id="floatingInput" [(ngModel)]="lexiText" [disabled]="lexiDisabled"
                                    name="lexiText" tabindex="0" rows="10" cols="50" formControlName="textArea" placeholder="Paste any text, max length 20000 chars"> </textarea>
                                <label class="" for="floatingInput"> Text </label>
                            </div>
    
                            <div class="col d-flex justify-content-center">
                                <button type="button" class="btn btn-primary w-75"
                                    [disabled]="firstFormGroup.controls['textArea'].errors"
                                    (click)="analyze()">Next</button>
                            </div>
    
                        </form>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="static-2" title="">
                <ng-template ngbPanelTitle>
                    <span><b>Select From History</b></span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="col-md-5 p-2">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="any text" #input>
                        </mat-form-field>
    
                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource" matSort>
    
                                <!-- Checkbox Column -->
                                <!-- <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row): null"
                                                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"
                                                formControlName="table">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container> -->
                                <!-- Raw Text Column -->
                                <ng-container matColumnDef="rawText">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Text </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.rawText?.length>40?row.rawText.substring(0,40)+'...':row.rawText}} </td>
                                </ng-container>
                                <!-- Title Column -->
                                <ng-container matColumnDef="title">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.title?.length>40?row.title.substring(0,40)+'...':row.title}} </td>
                                </ng-container>
                                <!-- Menu -->
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let row">
                                        <button mat-icon-button color="warn" (click)="deleteAnalysis(row)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                </ng-container>
                                <!-- # Found -->
                                <!-- <ng-container matColumnDef="numberOfLexis">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            title="Idioms/Phrasal Verbs/Single Words"> Lexi's Found
                                            &nbsp;<mat-icon inline="true">info_outline</mat-icon>
                                        </th>
                                        <td mat-cell *matCellDef="let row"> {{row.idioms?.length + "/" +
                                            row.phrasals?.length + "/" +
                                            row.singleWords?.length}} </td>
                                    </ng-container> -->
    
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"
                                    [ngClass]="{hovered: row.hovered, highlighted: isSelected(row)}"
                                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
    
                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                                </tr>
                            </table>
    
                            <mat-paginator [pageSize]="4" [pageSizeOptions]="[4, 10, 25]"></mat-paginator>
                        </div>
                    </div>
    
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </mat-step>
    <mat-step  state="step2">
        <ng-template matStepLabel>RESULTS</ng-template>
        Add more details for reference. 
        <span><button type="button" class="btn btn-sm btn-link" (click)="collapse.toggle()">
            Expand
        </button></span>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="showAnalysisDetails">
            <div class="card">
                <div class="card-body">
                    <form class="w-100 p-2 d-flex" [formGroup]="secondFormGroup">
                        <div class="container-fluid">
                            <div class="row gap-3 d-flex ">
            
                                <mat-form-field appearance="fill">
                                    <mat-label>Title</mat-label>
                                    <input matInput formControlName="title"
                                        placeholder="Optional title to help you remember your analysis later ">
                                </mat-form-field>
                            </div>
                            <div class="row gap-3 d-flex ">
            
                                <mat-form-field appearance="fill">
                                    <mat-label>URL</mat-label>
                                    <input matInput formControlName="url" placeholder="Optional URL for future reference">
                                </mat-form-field>
                            </div>
                            <div class="row gap-3 d-flex ">
                                <div class="col d-flex justify-content-center">
                                    <button type="button" class="btn btn-primary w-50" (click)="save()">Save
                                        Analysis</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <app-analysis-results [inputAnalysis]="analysisResult"></app-analysis-results>

    </mat-step>

