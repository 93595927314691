import { Component, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert, AlertType } from '../models/alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  //   private subject = new Subject<Alert>();
  //   private keepAfterRouteChange = false;

  constructor(private snackBar: MatSnackBar) {}

  // convenience methods
  success(message: string) {
    this.snackBar.open(message,'', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  error(message: string, alertId?: string) {
    this.snackBar.open(message, '', {
      panelClass: ['alert', 'alert-danger'],
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  info(message: string, alertId?: string) {
    this.snackBar.open(message, '', {
      panelClass: ['alert', 'alert-info'],
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  warn(message: string, alertId?: string) {
    this.snackBar.open(message, '', {
      panelClass: ['alert', 'alert-warning'],
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}

