import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FlashcardModel } from 'src/app/models/flashcard.model';
import { FlashcardService } from 'src/app/services/flashcard.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  constructor(private rout:Router){
    // if(window.innerWidth>768){
    //     this.rout.navigate(['/flashcards'])
    //   }else{
    //     this.rout.navigate(['/quick-create'])
    //   }
  }
  ngOnInit() {
  }

}
