import { Component, OnInit , Input} from '@angular/core';
import {SearchResult} from '../../models/search-result';
import {FavoriteService} from '../../services/favorite.service';
import {Router, RouterEvent, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { FlashcardService } from 'src/app/services/flashcard.service';
import { MatDialog } from '@angular/material/dialog';
import { FlashcardCreatePopoverComponent } from '../flashcard-create-popover/flashcard-create-popover.component';


@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {

searchResult: SearchResult[];
lastListId: number;

result:boolean;

  constructor(private favoriteService:FavoriteService,private router: Router,private alertService:AlertService,    private dialog: MatDialog,
    private flashcardService: FlashcardService) { 
    this.searchResult=this.router.getCurrentNavigation().extras.state.searchResults;

  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.searchResult=this.router.getCurrentNavigation().extras.state.searchResults;

    });


  }

  openFlashcardDialog(searchResult:SearchResult) {
    const dialogRef = this.dialog.open(FlashcardCreatePopoverComponent, {
      data: { lexiText: searchResult.word, listId: this.lastListId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.lastListId = result;
    });
  }

  // addFavorite(searchResult:SearchResult){
  //   this.result=null;
  //   const searchResultObservable = this.favoriteService.createFlashcard(searchResult.word,searchResult.);
  //   searchResultObservable.subscribe(result => 
  //     {
  //       this.result=result.success;
  //       this.alertService.success("Favorite added to the list");
  //       console.log("Add to favorite result: "+result.message);
  //     }
  //     );
  // }

}