import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { FavoriteList, FlashcardModel } from '../models/flashcard.model';
import { GenericResponse } from '../models/generic-response';
import { SyncFlashcardRequestService } from './sync-flashcard-request.service';

@Injectable()
export class FavoriteService {
  private createFlashcardUrl: string = '/addFlashcard';
  private updateFlashcardUrl: string = '/updateFlashcard';
  private deleteFlashcardUrl: string = '/deleteFlashcard?id=';
  private _removeFavoriteUrl: string = '/removeFavorite?entry=';
  // private _getFavoritesUrl: string = '/getFavorites';
  private _getFavoriteListsUrl: string = '/getFavoriteLists';
  private _createNewListUrl: string = '/addFavoriteList?name=';

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private offlineFlashcardService: SyncFlashcardRequestService
  ) {}

  createFlashcard(
    word: string,
    listId: number,
    example: string
  ): Observable<GenericResponse> {
    //return this.http.post<boolean>(this._addFavoriteUrl,"\"entry\":\""+word+"\"");

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let body = '';
    body = body.concat(
      '{"lexiText":"',
      word,
      '","listId":"',
      listId.toString(),
      '","phrase":"',
      word,
      '","example":"',
      example,
      '"}'
    );

    return this.offlineFlashcardService.tryPostPayload(this.createFlashcardUrl, body,headers);

    // return this.http.post<GenericResponse>(this.createFlashcardUrl, body, {
    //   headers,
    // });


  }

  updateFlashcard(flashcard: FlashcardModel): Observable<GenericResponse> {
    //return this.http.post<boolean>(this._addFavoriteUrl,"\"entry\":\""+word+"\"");
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let body = '';
    body = JSON.stringify(flashcard);

    return this.http.post<GenericResponse>(this.updateFlashcardUrl, body, {
      headers,
    });
  }

  deleteFlashcard(flashcard: FlashcardModel): Observable<GenericResponse> {
    return this.http.delete<GenericResponse>(
      this.deleteFlashcardUrl + flashcard.id,
      {}
    );
  }

  removeFavorite(word: string): Observable<boolean> {
    return this.http.post<boolean>(
      this._removeFavoriteUrl + encodeURIComponent(word),
      '',
      {}
    );
  }

  // getFavorites(): Observable<String[]> {
  //   return this.http.get<String[]>(this._getFavoritesUrl, {});
  // }

  getFavoriteLists(): Observable<FavoriteList[]> {
    return this.http.get<FavoriteList[]>(this._getFavoriteListsUrl, {});
  }

  createNewList(name: String): Observable<GenericResponse> {
    if (!name) {
      return of(new GenericResponse('List name cannot be empty', false));
    }
    return this.http.post<GenericResponse>(this._createNewListUrl + name, {});
  }
}
