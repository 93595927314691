<h2 class="text-center ">Flashcards</h2>
<div class="container mt-3">
    <div class="row d-flex flex-wrap">
        <div class="col d-flex align-items-center">
            <div class="form-floating mb-1 w-100">
                <input class="form-control" [(ngModel)]="filterText" autocomplete="off" placeholder="Filter Flashcards"
                (keyup)="applyFilter()">
                <label class="" for="floatingInput"> Filter </label>
            </div>
        </div>
        <div class="col d-flex align-items-center">
            <div ngbDropdown class="d-inline-block" container="body">
                <button class="btn btn-outline-primary" id="listDropdown" ngbDropdownToggle>{{listFilterText}}</button>
                <div ngbDropdownMenu >
                    <button mat-button color="primary" (click)="selectAll()">Select All</button>
                    <button mat-button color="primary" (click)="deselectAll()">Select None</button>

                    <mat-selection-list #matList (selectionChange)="updateSelectedLists()">
                        <mat-list-option checkboxPosition="before" [value]="list.favoriteId" selected *ngFor="let list of lists | async">
                          {{list.name}}
                        </mat-list-option>
                      </mat-selection-list>
                    <!-- <div class="dropdown-divider"></div> -->
                </div>
            </div>
            <button class="btn btn-outline-accent" id="clearFilters" (click)="clearAllFilters()">Reset Filters</button>
            <button class="btn btn-primary" id="study" (click)="study()">Study Current Lists</button>
        </div>
    </div>

    <div class="container-fluid p-2 d-flex flex-wrap align-items-start">
        <div *ngFor="let item of  filteredFlashcards | async" class="card m-1" style="width: 18rem;">
            <div class="card-body">
                <h5 class="card-title">{{item.phrase | indefinitepronoun}} </h5>
                <mat-card-subtitle>{{item.listName}} </mat-card-subtitle>
                <p class="card-text">{{item.example | longtext}} &nbsp; </p>
                <button class="btn btn-outline-primary" id="editFlashcard" (click)="editFlashcardDialog(item)">Edit</button>
                <a mat-button href={{item.url}} target="_blank">Source</a>
            </div>
        </div>
    </div>
</div>