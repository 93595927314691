<h2 class="text-center">Movie Search</h2>
<div class="container mt-3">
  <div class="row d-flex flex-wrap align-items-center">
    <div class="col d-flex align-items-center">
      <div class="form-floating mb-1 w-100">
        <input
          class="form-control"
          [(ngModel)]="searchTerm"
          autocomplete="off"
          placeholder="Search for a movie/series"
          (keydown.enter)="triggerSearch(searchTerm)"
        />
        <button
          mat-button
          *ngIf="searchTerm"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchTerm = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
        <label class="" for="floatingInput"> Movie/Series Name </label>
      </div>
    </div>
    <div class="d-flex w-auto">
      <button
        class="btn btn-primary"
        id="study"
        (click)="triggerSearch(searchTerm)"
      >
        Search
      </button>
    </div>
  </div>
</div>

<div *ngIf="movieSearchResult?.search.length > 0" class="auto-grid mt-2">
  <div *ngFor="let movieDetail of movieDetails" class="grid-box">
    <h5 class="card-title">{{ movieDetail.title }}</h5>
    <a href="{{ movieDetail.imdbID }}">
      <img class="grid-img" src="{{ movieDetail.poster }}"
    /></a>
    <p>
      Year: {{ movieDetail.year }}
      <br />
      Type: {{ movieDetail.type }}
    </p>
    <button
      class="btn btn-sm btn-primary m-top"
      color="accent"
      (click)="getDetails(movieDetail.imdbID, movieDetail.title)"
    >
      <mat-icon>search</mat-icon> Details
    </button>

    <!-- <mat-card-subtitle>{{ item.listName }} </mat-card-subtitle>
      <p class="card-text">{{ item.example | longtext }} &nbsp;</p> -->
  </div>
</div>
