import { Component } from '@angular/core';
import { ToastInfo, ToastLevel } from 'src/app/models/toast-model';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
  constructor(public toastService: ToastService) {}
  calcClass="bg-success text-light"

  isSuccess(toast:ToastInfo):boolean{
    return toast.level === ToastLevel.success;
  }

  isWarning(toast:ToastInfo):boolean{
    return toast.level === ToastLevel.warning;
  }

  isError(toast:ToastInfo):boolean{
    return toast.level === ToastLevel.error;
  }

  getCssClass(toast:ToastInfo):string{
    if(toast.level == "success") return 'bg-success'
    if(toast.level == "warning") return 'bg-warning'
    if(toast.level == "error") return 'bg-danger'
    return ""
  }


}
