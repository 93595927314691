<h2 class="pl-3 pt-3 m-0">Encounters</h2>

<div class="container mt-2">
    <div class="row d-flex flex-wrap">

        <div class="container">

        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100" appearance="fill" (click)="$event.stopPropagation();">
                    <mat-label>Filter Items</mat-label>
                    <input matInput [(ngModel)]="filterText" autocomplete="off" placeholder="Filter Encounters"
                        (keyup)="applyFilter($event)">
                </mat-form-field>
            </div>
            <div class="col-3">
                <div ngbDropdown class ="w-100 h-75 d-inline-block" container="body">
                    <button mat-raised-button class ="w-100 h-100 d-inline-block"  color="primary" id="listDropdown"
                        ngbDropdownToggle>{{listFilterText}}</button>
                    <div ngbDropdownMenu>
                        <button mat-button color="primary" (click)="selectAll()">Select All</button>
                        <button mat-button color="primary" (click)="deselectAll()">Select None</button>

                        <mat-selection-list #matList (selectionChange)="updateSelectedLists()">
                            <mat-list-option checkboxPosition="before" [value]="list.favoriteId" selected
                                *ngFor="let list of favoriteLists | async">
                                {{list.name}}
                            </mat-list-option>
                        </mat-selection-list>
                        <div class="dropdown-divider"></div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <button mat-stroked-button class ="w-100 h-75 d-inline-block" color="primary" id="clearFilters" (click)="clearAllFilters()">Reset</button>
            </div>
        </div>
    </div>


        <!-- <mat-form-field class="example-form-field" appearance="fill">
                    <mat-label>Data range</mat-label>
                    <mat-date-range-input [formGroup]="dates" [rangePicker]="datePicker"
                        [comparisonStart]="dates.value.start" [comparisonEnd]="dates.value.end">
                        <input matStartDate placeholder="Start date" formControlName="start">
                        <input matEndDate placeholder="End date" formControlName="end"
                            (dateChange)="dateRangeChanged('endEvent',$event)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker touchUi #datePicker></mat-date-range-picker>
                </mat-form-field> -->



    </div>


    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Flashcard -->
            <ng-container matColumnDef="flashcard_text">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Flashcard </th>
                <td mat-cell *matCellDef="let row"> {{row.flashcardText}} </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="encounter_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <!-- Text -->
            <ng-container matColumnDef="encounter_text">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Text </th>
                <td mat-cell *matCellDef="let row"> {{row.text}}</td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row"> {{row.status}} </td>

            </ng-container>

            <!-- Type -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                <td mat-cell *matCellDef="let row">
                    <a href="{{row.url}}">
                        <img *ngIf="row.type == 'TWITTER'"
                            src="../../../assets/Twitter social icons - circle - blue.svg" alt="Twitter URL" width="20"
                            height="20">
                    </a>
                </td>

                <td mat-cell *matCellDef="let row">
                    <mat-icon *ngIf="row.type == 'TWITTER'" class="red">remove</mat-icon>
                </td>
                <td mat-cell *matCellDef="let row"> {{row.type}} </td>
            </ng-container>




            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="deleteEncounter(row.id)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{filterText}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of transactions"></mat-paginator>
    </div>

</div>