import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { AnalysisDto } from 'src/app/models/analysis-model';
import { ToastService } from 'src/app/services/toast.service';
import { FavoriteList, FlashcardModel } from 'src/app/models/flashcard.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { QuizService } from 'src/app/services/quiz.service';
import { QuizRequest, QuizResponse } from 'src/app/models/quiz.model';

@Component({
  selector: 'app-flashcard-study',
  templateUrl: './flashcard-study.component.html',
  styleUrls: ['./flashcard-study.component.scss'],
})
export class FlashcardStudyComponent implements AfterViewInit, OnInit {
  dataSource: MatTableDataSource<FlashcardModel>;
  selection = new SelectionModel<FlashcardModel>(true, []);
  selection$: Observable<SelectionModel<FlashcardModel>> = of(this.selection);
  displayedColumns: string[] = ['select', 'phrase', 'list', 'example'];
  loading: boolean = false;

  quizOptionsForm = new FormGroup({
    answerType: new FormControl(1),
    questionFindTheWord: new FormControl(true),
    questionFindTheDefinition: new FormControl(true),
    questionFindTheSynonym: new FormControl(true),
    questionFillInTheBlanks: new FormControl(true),
    numberOfQuestions: new FormControl(5),
    // noChoices: new FormControl(''),
  });

  flashcards: FlashcardModel[];
  public isFlashcardsCollapsed = true;

  quizResponse:Observable<QuizResponse> 

  //If table is inside a ngb-panel, this is the way to define a paginator. Otherwise paginator lose state after panel reactivation
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(NgbAccordion) accordion: NgbAccordion;

  constructor(
    private toastService: ToastService,
    private quizService: QuizService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.flashcards = data.flashcards;
    this.dataSource = new MatTableDataSource(data.flashcards);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.toggleAllRows();
  }
  ngOnInit(): void {
    this.adjustTableColumnsForResolution(window.innerWidth);
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private adjustTableColumnsForResolution(innerWidth: number) {
    if (innerWidth < 1000) {
      this.displayedColumns = ['select', 'phrase'];
    } else {
    }
  }

  save() {
    // this.analysisResult.title = this.secondFormGroup.controls['title'].value;
    // this.analysisResult.url = this.secondFormGroup.controls['url'].value;
    // this.textAnalysisService
    //   .saveAnalysis(this.analysisResult)
    //   .subscribe((result) => {
    //     if (result.success) {
    //       this.alertService.success(result.message);
    //       this.refreshAnalysisList();
    //     } else {
    //       this.alertService.error(result.message);
    //     }
    //   });
  }
  stepTransition(event: StepperSelectionEvent) {
    if (event.selectedIndex == 0) {
      // this.refreshAnalysisList();
      //this.firstFormGroup.reset();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  selectRow(row?: FlashcardModel) {
    this.selection.toggle(row);
  }

  generateQuiz() {
    this.accordion.toggle('quiz-results');
    let flashcardString = this.selection.selected
      .map((flashcard) => flashcard.phrase)
      .join(', ');

  }

  public beforePanelChange($event: NgbPanelChangeEvent) {
    if ($event.panelId === 'quiz-options') {
      //this.ngAfterViewInit();
    }
  }

  getQuestionsFromBackend(){
    let quizRequest: QuizRequest = new QuizRequest();
    quizRequest.setFlashcards(this.selection.selected.map((flashcard) => flashcard.id));
    quizRequest.setQuestionFindTheWord(this.quizOptionsForm.get('questionFindTheWord').value);
    quizRequest.setQuestionFindTheDefinition(this.quizOptionsForm.get('questionFindTheDefinition').value);
    quizRequest.setQuestionFindTheSynonym(this.quizOptionsForm.get('questionFindTheSynonym').value);
    quizRequest.setQuestionFillInTheBlanks(this.quizOptionsForm.get('questionFillInTheBlanks').value);
    quizRequest.setAnswerType(this.quizOptionsForm.get('answerType').value==1?"Multiple-choice":"No choice");

    this.quizResponse = this.quizService.generateQuiz(quizRequest);
  }

}
