import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Lexi,
  MovieAnalysisResult,
  MovieSearchResult,
} from 'src/app/models/movie-model';
import { AnalysisHelperService } from 'src/app/services/analyze.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map, mergeMap, filter, tap, takeWhile } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { AlertService } from 'src/app/services/alert.service';
import { BugService } from 'src/app/services/bug.service';
import { KnownWordsListService } from 'src/app/services/known-words-list.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { OverlayService } from 'src/app/services/overlay.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Subtitle } from 'src/app/models/subtitle.model';
import {
  AnalysisDto,
  AnalysisLexiDto,
  AnalysisLineDto,
} from 'src/app/models/analysis-model';
import { MatDialog } from '@angular/material/dialog';
import { FlashcardCreatePopoverComponent } from '../flashcard-create-popover/flashcard-create-popover.component';
import { FlashcardService } from 'src/app/services/flashcard.service';
import { FlashcardComponent } from '../flashcard/flashcard.component';
import { TextAnalysisService } from 'src/app/services/text-analysis.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { AnalysisResultsDetailsComponent } from '../analysis-results-details/analysis-results-details.component';
import { ToastService } from 'src/app/services/toast.service';
import { ToastLevel } from 'src/app/models/toast-model';

@Component({
  selector: 'app-analysis-results',
  templateUrl: './analysis-results.component.html',
  styleUrls: ['./analysis-results.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  // providers: [MovieService],
})
export class AnalysisResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  inputAnalysis: AnalysisDto;
  tabs: string[] = ['Idioms', 'Phrasals', 'Words'];
  analysisParts: Observable<AnalysisLexiDto[]>[];
  // @ViewChild("tabs") tabGroup: MatTabGroup;
  selectedIndexBinding: number;
  pageActive: boolean;

  retryCounter: number[];
  lines: Observable<AnalysisDto>;

  fetchingData: boolean;
  level: number;

  lexisInFlascards: String[];

  lastListId: number;

  taggedLines: Observable<AnalysisLineDto[]>;

  constructor(
    private favoriteService: FavoriteService,
    private toastService: ToastService,
    private knownWordService: KnownWordsListService,
    private overlayService: OverlayService,
    private dialog: MatDialog,
    private flashcardService: FlashcardService,
    private analysisService: TextAnalysisService,
    private analysisHelper: AnalysisHelperService
  ) {}

  ngOnInit(): void {
    this.pageActive = true;
    this.refreshLexisInFlashcardsList();
    // const localuser = JSON.parse(localStorage.getItem("currentUser"));
    this.level = 5;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputAnalysis?.currentValue) {
      this.retryCounter = Array(4).fill(0);
      // if(this.inputAnalysis.idioms){
      //   this.analysisParts[0]= of(this.inputAnalysis.idioms);
      // }
      // if(this.inputAnalysis.phrasals){
      //   this.analysisParts[1]=of(this.inputAnalysis.phrasals);
      // }
      // if(this.inputAnalysis.singleWords){
      //   this.analysisParts[2]=of(this.inputAnalysis.singleWords);
      // }
      this.analysisParts = Array(3).fill(null);
      this.selectedIndexBinding = 0;
      this.taggedLines = undefined;
      this.fetchPartialAnalysis();
    }
  }

  ngOnDestroy(): void {
    this.pageActive = false;
  }

  onTabChanged(event: MatTabChangeEvent) {
    if (this.selectedIndexBinding == 3) {
      this.getTaggedLines();
    } else {
      this.fetchPartialAnalysis();
    }
  }

  fetchPartialAnalysis() {
    let section: number = this.selectedIndexBinding
      ? this.selectedIndexBinding
      : 0;
    if (!this.analysisParts[section]) {
      this.fetchingData = true;
      this.analysisService
        .getPartialAnalysis(this.inputAnalysis?.pk, section)
        .pipe(takeWhile(() => this.pageActive))
        .subscribe((result) => {
          if (result) {
            this.analysisParts[section] = of(result);
            this.fetchingData = false;
          } else {
            this.retryCounter[section]++;
            if (this.retryCounter[section] < 10) {
              setTimeout(() => {
                console.log('Retrying... - ' + this.retryCounter[section]);
                this.fetchPartialAnalysis();
              }, 1000);
            }
          }
        });
    } else {
      this.fetchingData = false;
    }
  }

  openLexiDetailsDialog(lexi: AnalysisLexiDto , lexiText:string) {
    const dialogRef = this.dialog.open(AnalysisResultsDetailsComponent, {
      height: '600px',
      width: '600px',
      data: {
        pk: this.inputAnalysis.pk,
        lexi: lexi,
        lexiText: lexiText?lexiText:lexi.lexiText,
        lexiInFlascards: this.isLexiInFlashcards(lexiText),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.refreshLexisInFlashcardsList();
    });
  }

  getTaggedLines() {
    console.log('Lines initiated');

    if (!this.taggedLines) {
      if (
        !(
          this.inputAnalysis.lineDetails &&
          this.inputAnalysis.idioms &&
          this.inputAnalysis.phrasals &&
          this.inputAnalysis.singleWords
        )
      ) {
        this.fetchingData = true;
        this.analysisService
          .getFullAnalysis(this.inputAnalysis.pk)
          .pipe(takeWhile(() => this.pageActive))
          .subscribe((result) => {
            if (
              result.lineDetails &&
              result.idioms &&
              result.phrasals &&
              result.singleWords
            ) {
              this.inputAnalysis.lineDetails = result.lineDetails;
              this.inputAnalysis.idioms = result.idioms;
              this.inputAnalysis.phrasals = result.phrasals;
              this.inputAnalysis.singleWords = result.singleWords;

              this.taggedLines = of(this.buildLineStructure());
              this.fetchingData = false;
            } else {
              if (this.retryCounter[3] < 10) {
                setTimeout(() => {
                  console.log('Retrying... - ' + this.retryCounter[3]);
                  this.getTaggedLines();
                }, 2000);
              }
            }
          });
      }
    }
  }

  buildLineStructure(): AnalysisLineDto[] {
    let lines: AnalysisLineDto[] = this.inputAnalysis?.lineDetails;
    if (lines) {
      lines.forEach((line) => {
        line.lineIdioms = [];
        line.linePhrasals = [];
        line.lineSingleWords = [];
      });
      this.inputAnalysis.idioms?.forEach((idiom) => {
        idiom.relatedLines.forEach((relatedLine) =>
          lines[relatedLine.lineId].lineIdioms?.push(idiom.lexiText)
        );
      });
      this.inputAnalysis.phrasals?.forEach((phrasal) => {
        phrasal.relatedLines.forEach((relatedLine) => {
          lines[relatedLine.lineId].linePhrasals?.push(phrasal.lexiText);
        });
      });
      this.inputAnalysis.singleWords?.forEach((singleWord) => {
        singleWord.relatedLines.forEach((relatedLine) =>
          lines[relatedLine.lineId].lineSingleWords?.push(singleWord.lexiText)
        );
      });
    }
    return lines;
  }

  addToKnownWords(text: string) {
    console.log('Add to known words: ' + text);
    const searchResultObservable = this.knownWordService.addKnownWord(text);
    searchResultObservable.subscribe((flag: boolean) => {
      if (flag) {
        this.toastService.show('Known word added to the list',ToastLevel.success);
      } else {
        this.toastService.show('Already in the known words',ToastLevel.warning);
      }
    });
  }

  openFlashcardDialog(lexiDto: AnalysisLexiDto) {
    const dialogRef = this.dialog.open(FlashcardCreatePopoverComponent, {
      data: {
        lexiText: lexiDto.lexiText,
        listId: this.lastListId,
        lexiDisabled: true,
        examples: lexiDto.relatedLines
          .map((line) => line.lineText.replace(/(\r\n|\n|\r)/gm, ''))
          .join(' | '),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.lastListId = result;
      this.refreshLexisInFlashcardsList();
    });
  }

  refreshLexisInFlashcardsList() {
    this.flashcardService
      .getLexisInFlashcards()
      .subscribe((lexis) => (this.lexisInFlascards = lexis));
  }

  openFlashcardsListDialog(lexiDto: AnalysisLexiDto) {
    const dialogRef = this.dialog.open(FlashcardComponent, {
      data: { filterText: lexiDto.lexiText },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  isLexiInFlashcards(lexiText: string): boolean {
    return this.lexisInFlascards.filter((lexi) => lexi === lexiText).length > 0;
  }

  formatLabel(value: number) {
    if (value === 5) {
      return 'All';
    }

    return value;
  }
}
