import { AfterContentInit, AfterViewInit, Component, Inject, Input, OnChanges, OnInit, Optional, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  tap,
} from 'rxjs/operators';
import { FavoriteList, FlashcardModel } from 'src/app/models/flashcard.model';
import { IndefinitePronounPipe } from 'src/app/pipe/indefinitePronoun.pipe';
import { FavoriteService } from 'src/app/services/favorite.service';
import { FlashcardService } from 'src/app/services/flashcard.service';
import { EditFlashcardComponent } from '../flashcard-edit/flashcard-edit.component';
import { FlashcardStudyComponent } from '../flashcard-study/flashcard-study.component';

@Component({
  selector: 'app-flashcard',
  templateUrl: './flashcard.component.html',
  styleUrls: ['./flashcard.component.scss'],
})
export class FlashcardComponent implements OnInit{
  constructor(
    private flashcardService: FlashcardService,
    private favoriteService: FavoriteService,
    private indefinitePronounPipe: IndefinitePronounPipe,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  @Input()
  filterText: string;

  flashcards$: Observable<FlashcardModel[]> =
    this.flashcardService.getFlashcards();

  lists: Observable<FavoriteList[]> = this.favoriteService.getFavoriteLists();
  filteredFlashcards: Observable<FlashcardModel[]>;
  totalNumberOfLists: number;
  selectedLists: number[];
  listFilterText: string;

  @ViewChild('matList') 
  matList: MatSelectionList;

  ngOnInit() {
    this.lists.subscribe((listsFromBackend) => {
      this.totalNumberOfLists = listsFromBackend.length;
      this.selectedLists = listsFromBackend.map((list) => {
        return list.favoriteId;
      });
      this.listFilterText =
        this.totalNumberOfLists +
        ' of ' +
        this.totalNumberOfLists +
        ' Lists';

        this.filterText = this.indefinitePronounPipe.transform(this.data?.filterText);

    });
    this.filteredFlashcards = this.flashcards$;

    this.updateSelectedLists();
  }

  selectAll() {
    this.matList?.selectAll();
    this.updateSelectedLists();
  }

  deselectAll() {
    this.matList.deselectAll();
    this.updateSelectedLists();
  }

  clearAllFilters() {
    this.selectAll();
    this.filterText = '';
    this.updateSelectedLists();
    this.applyFilter();
  }

  updateSelectedLists() {
    this.selectedLists = this.matList?.selectedOptions.selected.map((option) => {
      return option.value;
    });
    this.listFilterText =
      this.selectedLists?.length +
      ' of ' +
      this.totalNumberOfLists +
      ' Lists';
    this.applyFilter();
  }

  getAllFlashcardsForSelectedLists():Observable<FlashcardModel[]>{
    return this.flashcards$.pipe(
      map((flashcards) => {
        return flashcards.filter((flashcard) => {
          return this.selectedLists.includes(flashcard.listId);
        });
      }))
  }

  applyFilter() {
    this.filteredFlashcards = this.getAllFlashcardsForSelectedLists().pipe(
      // tap((x) => console.log('After list filter', x.length)),
      debounceTime(200),
      distinctUntilChanged(),
      map((flashcards) => {
        if (this.filterText) {
          return flashcards.filter((flashcard) => {
            return (
              this.checkField(flashcard.phrase) ||
              this.checkField(flashcard.example) ||
              this.checkField(flashcard.lexiText) ||
              this.checkField(this.indefinitePronounPipe.transform(flashcard.lexiText))
            );
          });
        } else {return flashcards;}
      }),
      // tap((x) => console.log('After text filter', x.length))
    );
  }

  editFlashcardDialog(flashcard:FlashcardModel) {
    const dialogRef = this.dialog.open(EditFlashcardComponent, {
      height:"90%", width: "90%",  maxWidth: '700px', maxHeight: '700px',data: { flashcard: flashcard },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.applyFilter();
    });
  }

  study(){

    this.getAllFlashcardsForSelectedLists().subscribe(flashcards => {
      const dialogRef = this.dialog.open(FlashcardStudyComponent, {
        height:"90%", width: "90%", data: { flashcards: flashcards },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.applyFilter();
      });

    });
  }

  private checkField(text:string):boolean{
    return text
    ?.toLowerCase()
    .includes(this.filterText?.toLowerCase())
  }
}
// https://stackoverflow.com/questions/58640506/how-can-i-filter-data-using-cards-and-not-data-tables-in-angular-8

// https://ng-bootstrap.github.io/#/components/dropdown/examples
