import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectionList } from '@angular/material/list';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Observable } from 'rxjs';
import { EncounterModel } from 'src/app/models/encounter.model';
import { FavoriteList } from 'src/app/models/flashcard.model';
import { AlertService } from 'src/app/services/alert.service';
import { EncounterService } from 'src/app/services/encounter.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { CommonModule } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { ToastLevel } from 'src/app/models/toast-model';

@Component({
  selector: 'app-encounters',
  templateUrl: './encounters.component.html',
  styleUrls: ['./encounters.component.scss'],
})
export class EncountersComponent implements OnInit {
  expandedElement!: EncounterModel | null;

  favoriteLists: Observable<FavoriteList[]> = this.favoriteService.getFavoriteLists();

  filterText: string = '';
  listMap = new Map();

  encounters: EncounterModel[] = [];

  filteredEncounters: EncounterModel[] = [];
  selectedLists: number[];
  listFilterText!: string;

  displayedColumns: string[] = [
    'flashcard_text',
    'encounter_id',
    'encounter_text',
    'status',
    'type',
    'actions',
  ];

  @ViewChild('matList')
  matList!: MatSelectionList;

  dataSource: MatTableDataSource<EncounterModel> = new MatTableDataSource(
    this.encounters
  );

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dates!: UntypedFormGroup;

  constructor(
    private encounterService: EncounterService,
    private favoriteService: FavoriteService,
    private toastService: ToastService
  ) {
    this.dataSource = new MatTableDataSource(this.filteredEncounters);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.selectedLists = [];
    this.refreshDates();

    this.refreshEncounters();
  }

  encounters$: Observable<EncounterModel[]> =
    this.encounterService.getEncounters();

  ngOnInit(): void {
        // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        this.dataSource.filterPredicate = (data, filter): boolean => {
          return data.text.toLowerCase().includes(filter.toLowerCase());
        };
        this.favoriteLists.subscribe((listsFromBackend) => {
          listsFromBackend.forEach((list) =>
            this.listMap.set(list.favoriteId, list.name)
          );
    
          this.selectedLists = Array.from(this.listMap.keys());
          listsFromBackend.map((list) => {
            return list.favoriteId;
          });
          this.listFilterText =
          'Lists('+this.listMap.size+'/'+this.listMap.size+')';
    
        });
    
        this.filteredEncounters = this.encounters;
        this.updateSelectedLists();
  }

  private refreshDates() {
    const today = new Date();
    const startDay = new Date();
    startDay.setDate(today.getDate() - 30);
    this.dates = new UntypedFormGroup({
      start: new UntypedFormControl(
        new Date(
          startDay.getFullYear(),
          startDay.getMonth(),
          startDay.getDate()
        )
      ),
      end: new UntypedFormControl(
        new Date(today.getFullYear(), today.getMonth(), today.getDate())
      ),
    });
  }

  selectAll() {
    this.matList?.selectAll();
    this.updateSelectedLists();
  }

  deselectAll() {
    this.matList.deselectAll();
    this.updateSelectedLists();
  }

  clearAllFilters() {
    this.selectAll();
    this.filterText = '';
    this.updateSelectedLists();
    this.applyListFilter();
    this.refreshDates();
    this.dataSource.filter='';
  }

  updateSelectedLists() {
    this.selectedLists = this.matList?.selectedOptions.selected.map(
      (option) => {
        return option.value;
      }
    );
    this.listFilterText =
      'Lists('+this.selectedLists?.length+'/'+this.listMap.size+')';
    this.applyListFilter();
  }

  applyListFilter() {
    this.filteredEncounters = this.encounters.filter((encounter) => {
      return (
        !this.selectedLists ||
        this.selectedLists.includes(encounter.listId)
      );
    });

    this.dataSource.data = this.filteredEncounters;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteEncounter(id: number | undefined) {
    if (id) {
      this.encounterService.deleteEncounters(id).subscribe(
        (result) => {
          if (result.success) {
            this.toastService.show('Encounter deleted',ToastLevel.success);
            this.refreshEncounters();

          } else {
            this.toastService.show(
              'Something went wrong in deleting an encounter',ToastLevel.warning
            );
          }
        },
        (error) => {
          this.toastService.show(
            'Something went wrong in deleting an encounter',ToastLevel.error
          );
          console.error(error);
        }
      );
    }
  }

  refreshEncounters() {
    this.encounterService
      .getEncounters()
      .subscribe((encounters) => {
        this.encounters = encounters;
        this.filteredEncounters = encounters.sort((a, b) => {
          if (a.id <= b.id) {
            return 1;
          } else return -1;
        });
        this.dataSource.data = this.filteredEncounters;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  dateRangeChanged(dateField: string, event: MatDatepickerInputEvent<Date>) {
    if (
      dateField === 'endEvent' &&
      this.dates.value.start &&
      this.dates.value.end
    ) {
      this.refreshEncounters();
    }
  }


}
