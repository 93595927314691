<div class="col-md-6 offset-md-3 mt-5">
    <div class="alert alert-info">
        Let's get going!
    </div>
    <div class="card">
        <h4 class="card-header">Login</h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">E-mail address</label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': f.username.touched && f.username.errors }"/>
                    <div *ngIf="f.username.touched && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">E-mail address is required</div>
                        <div *ngIf="f.username.errors.email">E-mail format is wrong</div>
                   </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }" />
                    <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <span class="forgot-password-link" routerLinkActive="active" routerLink="/forgotPassword">Forgot Password?</span>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">Could not be authenticated, please try again.</div>
            </form>
        </div>
    </div>
</div>