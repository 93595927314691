<div class="container mt-3">
    <h3 class="card-title">{{lexiText | indefinitepronoun}}
        <span [hidden]="!lexiInFlascards">
            <mat-icon color="warn" class="ml-1" ngbTooltip="You already have a flashcard for this item"
                container="body">
                favorite</mat-icon>
        </span>
        <span>
            <button class="btn ml-3 btn-sm btn-outline-primary" id="lexiActions" [matMenuTriggerFor]="menu"
                #menuTrigger>Actions</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openFlashcardDialog(lexi,lexiText)">
                    <mat-icon>collections_bookmark</mat-icon>Create Flashcard
                </button>
                <button mat-menu-item (click)="addToKnownWords(lexiText)">
                    <mat-icon>disabled_visible</mat-icon>Add to Known Words
                </button>
                <button mat-menu-item (click)="openFlashcardsListDialog(lexiText)" [hidden]="!lexiInFlascards">
                    <mat-icon>favorite</mat-icon>See Flashcards
                </button>
                <button mat-menu-item (click)="addToBug(lexi,'')">
                    <mat-icon>bug_report</mat-icon>Report Bug
                </button>
            </mat-menu>
        </span>
    </h3>

</div>
<mat-accordion multi>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b>Definition</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <mat-list class="" *ngFor="let definition of exactDefinitions | async">
                <mat-list-item>
                    <span>{{definition}}</span>
                </mat-list-item>
            </mat-list>
            <div class="ml-3" *ngIf="definitionMap.size>0">
            No relevant definitions? 
            <button type="button" class="btn btn-sm btn-link" (click)="collapse.toggle()">
                See similar words
            </button>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="showAllDefinitions">
                <div class="card">
                    <div class="card-body">
                        <mat-list class="" *ngFor="let recipient of definitionMap | keyvalue">
                            <mat-list-item>
                                <span><b>{{recipient.key}} </b>--> {{recipient.value}}</span>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>

        </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b>Lines with the Phrase</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list class="" *ngFor="let line of lexi?.relatedLines ;index as lineNo">
            <mat-list-item [ngSwitch]="line" class="" (mouseover)="nearbyButtonVisible[lineNo]=true"
                (mouseout)="nearbyButtonVisible[lineNo]=false">
                {{ line.lineId}} - {{ line.lineText}} 
                <span class="d-flex align-items-center">
                    <button type="button" class="btn btn-link only-show-on-hover"
                        (click)="nearbyLinesCollapsed[lineNo] = !nearbyLinesCollapsed[lineNo]">
                        Nearby Lines
                    </button>
                </span>

            </mat-list-item>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="nearbyLinesCollapsed[lineNo]">
                <div class="card">
                    <div class="card-body">
                        <div *ngFor="let nearbyLine of nearbyLines[lineNo] | async">
                            <span *ngIf="line.lineId===nearbyLine.lineId;else other_content"> <b>{{ nearbyLine.lineId}} - {{
                                    nearbyLine.lineText}} </b></span>
                            <ng-template #other_content>{{ nearbyLine.lineId}} - {{ nearbyLine.lineText}}</ng-template>
                        </div>
                    </div>
                </div>
            </div>

        </mat-list>
    </mat-expansion-panel>
</mat-accordion>

<!-- <button class="btn mt-3 btn btn-outline-primary" id="lexiActions" [matMenuTriggerFor]="menu"
    #menuTrigger>Actions</button> -->