<div class="d-flex justify-content-center">
    <div class="d-flex flex-column w-100 w-lg-50 mw ms-2 me-2">
        <div>
            <h2 mat-dialog-title>Create New List</h2>
        </div>
        <div mat-dialog-content>

            <form class="">
                <div class="form-floating mb-1">
                    <input class="form-control" id="floatingInput" [(ngModel)]="listName" 
                        name="listName" tabindex="0">
                    <label class="" for="floatingInput"> List Name</label>
                </div>
                <div class="d-flex">
                    <button class="btn btn-primary w-100" type="submit" (click)="createList()" tabindex="1">Create
                        </button>
                </div>
            </form>
        </div>
    </div>
</div>