import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlashcardCreatePopoverComponent } from '../flashcard-create-popover/flashcard-create-popover.component';

@Component({
  selector: 'app-flashcard-quick-create',
  templateUrl: './flashcard-quick-create.component.html',
  styleUrls: ['./flashcard-quick-create.component.scss']
})
export class FlashcardQuickCreateComponent implements OnInit {

  private dialog: MatDialog;

  constructor() { }

  ngOnInit(): void {
  }

}
