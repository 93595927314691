import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FavoriteList } from 'src/app/models/flashcard.model';
import { FavoriteService } from 'src/app/services/favorite.service';
import { CreateNewListPopupComponent } from '../create-new-list-popup/create-new-list-popup.component';
import { ToastService } from 'src/app/services/toast.service';
import { ToastLevel } from 'src/app/models/toast-model';
import { SyncFlashcardRequestService } from 'src/app/services/sync-flashcard-request.service';
import { SyncFlashcardRequest } from 'src/app/models/offline-flashcard-request';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-flashcard-create-popover',
  templateUrl: './flashcard-create-popover.component.html',
  styleUrls: ['./flashcard-create-popover.component.scss'],
})
export class FlashcardCreatePopoverComponent implements OnInit {

  lists: Observable<FavoriteList[]> = this.favoriteService.getFavoriteLists();
  public isCollapsed = true;
  loading = false;
  showWarning

  flashcardForm = new FormGroup({
    lexiText: new FormControl(''),
    list: new FormControl(''),
    example: new FormControl(''),
  });

  constructor(
    private favoriteService: FavoriteService,
    private toastService: ToastService,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private syncFlashcardService: SyncFlashcardRequestService
  ) { }

  ngOnInit(): void {
    this.flashcardForm.setValue({
      lexiText: this.data?.lexiText || "",
      list: localStorage.getItem('lastUsedList'),
      example: '',
    });

    if (this.data?.lexiDisabled) {
      this.flashcardForm.get('lexiText')?.disable();
    }

    this.showWarning = this.syncFlashcardService.getExistingSyncTasks().length > 0 ? true : false
  }

  refreshCustomerLists() {
    this.lists = this.favoriteService.getFavoriteLists();
  }

  openCreateListDialog() {
    const dialogRef = this.dialog.open(CreateNewListPopupComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.flashcardForm.get('list').setValue(localStorage.getItem('lastUsedList'));
      this.refreshCustomerLists();
    });
  }

  onListSelected(newValue: number) {
    localStorage.setItem('lastUsedList', this.flashcardForm.get('list').value.toString());
  }

  createFlashcard() {
    this.loading = true;
    this.showWarning = false;
    this.favoriteService
      .createFlashcard(this.flashcardForm.get('lexiText').value, Number(this.flashcardForm.get('list').value), this.flashcardForm.get('example').value)
      .subscribe(
        (result) => {
          if (result.message == "Flashcard was cached to be sent later") {
            this.toastService.show(
              result.message,
              ToastLevel.warning
            );
          } else if (result.success) {
            this.toastService.show(
              'Flashcard added to the list',
              ToastLevel.success
            );
          } else {
            this.toastService.show(
              'Something went wrong in creating a flashcard',
              ToastLevel.error
            );
          }
          this.loading = false;
        },
        (error) => {
          this.toastService.show(
            'Something went wrong in creating a flashcard: ' + error,
            ToastLevel.error
          );
          this.loading = false;
        }
      );
  }

  syncOfflineFlashcards() {
    this.showWarning = false
    this.loading = true;
    this.syncFlashcardService.sync().subscribe(
      (result) => {
        this.toastService.show(
          "Flashcards created",
          ToastLevel.success
        );

        this.loading = false;
      },
      (error) => {
        console.log(error)

        this.toastService.show(
          'Something went wrong in sync of flashcards: ' + error,
          ToastLevel.error
        );
        this.loading = false;
      }
    );
  }


}
