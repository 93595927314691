import { Pipe, PipeTransform } from '@angular/core';
import { AnalysisLexiDto } from '../models/analysis-model';

@Pipe({
  name: 'filterlevel',
  pure: true,
})
export class LevelFilterPipe implements PipeTransform {
  constructor() {}

  transform(list: AnalysisLexiDto[], level:number): AnalysisLexiDto[] {
    if(!level || !list){
      return list;
    }
    if(level===5){
      return list;
    }
    return list.filter(item=>{return item.level===level});
  }
}
