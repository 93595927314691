<div class="p-3 d-flex flex-column">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="quiz-options" (panelChange)="beforePanelChange($event)">
        <ngb-panel id="quiz-options" title="">
            <ng-template ngbPanelTitle>
                <span><b>Quiz Options</b></span>
            </ng-template>
            <ng-template ngbPanelContent>
                <p class="p-2">
                    <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()">
                        {{(selection$ | async).selected.length}} flashcards selected
                    </button>
                </p>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isFlashcardsCollapsed">
                    <div class="p-2 d-flex flex-column">

                        <div class="col-md p-2">
                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="dataSource" matSort>

                                    <!-- Checkbox Column -->
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <!-- Phrase Column -->
                                    <ng-container matColumnDef="phrase">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phrase </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.phrase?.length>40?row.phrase.substring(0,40)+'...':row.phrase}} </td>
                                    </ng-container>
                                    <!-- List -->
                                    <ng-container matColumnDef="list">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> List </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.listName?.length>40?row.listName.substring(0,40)+'...':row.listName}}
                                        </td>
                                    </ng-container>
                                    <!-- Example Column -->
                                    <ng-container matColumnDef="example">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Example </th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.example?.length>40?row.example.substring(0,40)+'...':row.example}}
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        (click)="selectRow(row)"
                                        [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                                        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                                    <!-- Row shown when there is no matching data. -->
                                    <!-- <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                                        </tr> -->
                                </table>

                                <mat-paginator [pageSize]="4" [pageSizeOptions]="[4, 10, 25]"></mat-paginator>
                            </div>
                        </div>

                    </div>
                </div>
                <form class="d-flex flex-row flex-wrap" [formGroup]="quizOptionsForm">
                    <!-- <div class="form-floating mb-1">
                        <input class="form-control" id="floatingInput" formControlName="lexiText"
                            name="lexiText" tabindex="0">
                        <label class="" for="floatingInput"> Word / Phrase</label>
                    </div>

                    <div class="d-flex mb-1">
                        <select required (change)="onListSelected($event.target.value)" class="form-select me-1" formControlName="list">
                            <option *ngFor="let list of lists | async" [value]="list.favoriteId" required name="list" >
                                {{list.name}}
                            </option>
                        </select>
                        <button class="btn btn-secondary text-nowrap" type="button" (click)="openCreateListDialog()">
                            New List
                        </button>
                    </div>

                    <div id="listHelp" class="form-text">Select a list for the flashcard or create a new one.</div>
                    <div class="form-floating mb-1">
                        <input class="form-control" id="exampleSentence" formControlName="example" name="example">
                        <label class="" for="exampleSentence"> Example</label>
                    </div> -->
                    <div class="d-flex flex-column p-2 ">
                        <div id="listHelp" class="form-text">Question Type</div>
                        <div class="form-check ">
                            <input class="form-check-input" type="checkbox" value="" id="questionFindTheWord"
                                formControlName="questionFindTheWord">
                            <label class="form-check-label" for="questionFindTheWord">
                                Find the word
                            </label>
                        </div>
                        <div class="form-check ">
                            <input class="form-check-input" type="checkbox" value="" id="questionFindTheDefinition"
                                formControlName="questionFindTheDefinition">
                            <label class="form-check-label" for="questionFindTheDefinition">
                                Find the definition
                            </label>
                        </div>
                        <div class="form-check ">
                            <input class="form-check-input" type="checkbox" value="" id="questionFindTheSynonym"
                                formControlName="questionFindTheSynonym">
                            <label class="form-check-label" for="questionFindTheSynonym">
                                Find the synonym
                            </label>
                        </div>
                        <div class="form-check ">
                            <input class="form-check-input" type="checkbox" value="" id="questionFillInTheBlanks"
                                formControlName="questionFillInTheBlanks">
                            <label class="form-check-label" for="questionFillInTheBlanks">
                                Fill in the blanks
                            </label>
                        </div>
                        <br />
                    </div>
                    
                    <div class="d-flex flex-column p-2">
                        <div id="listHelp" class="form-text">Answer Type</div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="answerType" id="answerTypeMultipleChoice"
                                formControlName="answerType" [value]="1">
                            <label class="form-check-label" for="answerTypeMultipleChoice">
                                Multiple Choice
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="answerType" id="answerTypeNoChoices"
                                formControlName="answerType" [value]="2">
                            <label class="form-check-label" for="answerTypeNoChoices">
                                No Choices
                            </label>
                        </div>
                        <br />
                    </div>
                    
                    <!-- <div id="listHelp" class="form-text">Number of questions</div> -->
                    <div class="form-floating mb-1 w-md-25 p-2">
                        <input class="form-control" id="floatingInput" formControlName="numberOfQuestions"
                            name="numberOfQuestions" tabindex="0">
                        <label class="" for="floatingInput"> Number of questions</label>
                    </div>

                    <div class="form-check">
                        <label class="form-check-label" >
                            Approximate ChatGPT tokens to be used: {{chatGPTTokens}}
                        </label>
                    </div>

                    <div class="col d-flex justify-content-start p-2">
                        <button type="button" class="btn btn-primary w-md-25"
                            (click)="getQuestionsFromBackend() " [disabled]="loading"> <span *ngIf="loading"
                                class="spinner-border spinner-border-sm mr-1"></span>
                            Generate Quiz</button>
                    </div>
                </form>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="quiz-results" title="">
            <ng-template ngbPanelTitle>
                <span><b>Quiz</b></span>
            </ng-template>
            <ng-template ngbPanelContent>

                {{quizResponse | async | json}}

            </ng-template>
        </ngb-panel>
    </ngb-accordion>

</div>