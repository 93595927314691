<!-- <div class="container-fluid p-2 d-flex flex-wrap align-items-start">
    <div *ngFor="let recipient of flashcards$ | async | keyvalue" class="panel shadow bg-success bg-opacity-5 d-flex flex-wrap justify-content-md-around p-1 m-1">
        <div class="container">
            <h3 class="m-1">{{recipient.key}}</h3>
        </div>

        <div *ngFor="let item of recipient.value" class="card m-1" style="width: 18rem;">
            <div class="card-body">
                <h5 class="card-title">{{item.phrase || indefinitepronoun}} </h5>
                <p class="card-text">{{item.example}}</p>
                <a href={{item.url}} class="card-link">Source</a>
            </div>
        </div>
    </div>
</div> -->
    Start

