<!-- didnt work out, trying to collapse menu when clicked on somewhere else -->
<a class="close-navbar-toggler collapsed" (click)="closeMenu()"> </a>

<nav class="navbar navbar-expand-lg navbar-light fixed-top navbar-vocpad">
  <div class="container-fluid">
    <img
      src="../../assets/vocpad-logo.png"
      alt="logo"
      class=""
      routerLinkActive="active"
      routerLink="/"
    />

    <!-- collapse -->
    <div
      class="navbar-collapse collapse justify-content-md-center"
      [ngbCollapse]="isMenuCollapsed"
    >
      <!-- links -->
      <ul class="navbar-nav mb-2 mb-md-0">
        <li class="nav-item me-5">
          <a
            class="nav-link active"
            (click)="closeMenu()"
            href="/#/quick-create"
            routerLinkActive="active"
            >Quick Flashcard</a
          >
        </li>
        <li class="nav-item me-5">
          <a
            class="nav-link active"
            (click)="closeMenu()"
            href="/#/flashcards"
            routerLinkActive="active"
            >Flashcards</a
          >
        </li>
        <li class="nav-item me-5">
          <a
            class="nav-link active"
            (click)="closeMenu()"
            href="/#/encounters"
            routerLinkActive="active"
            >Encounters</a
          >
        </li>
        <li class="nav-item me-5">
          <div ngbDropdown class="d-inline-block">
            <span class="nav-link active" ngbDropdownToggle>Other</span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <span class="nav-item" ngbDropdownItem>
                <a
                  class="nav-link active"
                  (click)="closeMenu()"
                  href="/#/textAnalysis"
                  routerLinkActive="active"
                  >Analyze Text</a
                >
                <a
                  class="nav-link active"
                  (click)="closeMenu()"
                  href="/#/movie-search"
                  routerLinkActive="active"
                  >Movie Search</a
                >
              </span>
            </div>
          </div>
        </li>
      </ul>
      <!-- links end -->

      <!-- search -->
      <div [hidden]="authenticated() == null" class=" ">
        <form class="d-flex" role="search">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button class="btn btn-light" type="submit">Go</button>
        </form>
      </div>
      <!-- search end -->
    </div>
    <!-- collapse end -->

    <div class="d-flex">
      <!-- login register -->
      <span class="mb-2">
        <button
          class="btn btn-outline-primary me-1"
          [hidden]="authenticated() != null"
          routerLinkActive="active"
          routerLink="/login"
        >
          Login
        </button>
        <button
          class="btn btn-outline-primary me-2"
          [hidden]="authenticated() != null"
        >
          Register
        </button>
      </span>
      <!-- login register end -->
      <!-- account details -->
      <div class="me-2" ngbDropdown [hidden]="authenticated() == null">
        <button
          mat-fab
          [style.backgroundImage]="
            'url(' + '../../../../../assets/vocpad-logo-mini-pale.png' + ')'
          "
          class="image"
          ngbDropdownToggle
        >
          <i
            class="bi bi-person-circle"
            style="font-size: 2rem; color: var(--bs-primary)"
          ></i>
        </button>
        <div
          ngbDropdownMenu
          [ngClass]="{
            'dropdown-menu': true,
            'dropdown-menu-end': isMenuCollapsed
          }"
          class=" "
        >
          <div ngbDropdownItem>{{ (getUser() | async)?.fullname }}</div>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem (click)="logout()" class="align-middle">
            <i class="bi bi-box-arrow-right"></i>
            <span> Logout</span>
          </button>
          <button ngbDropdownItem routerLink="/profile">
            <i class="bi bi-person-badge"></i>
            <span> Profile</span>
          </button>
          <button ngbDropdownItem routerLink="/knownwords">
            <i class="bi bi-eye-slash"></i>
            <span> Known Words</span>
          </button>
        </div>
      </div>
      <!-- account details end -->

      <!-- hamburger -->
      <button
        class="navbar-toggler collapsed"
        type="button"
        (click)="isMenuCollapsed = !isMenuCollapsed"
      >
        <i class="bi bi-list"></i>
        <!-- <span class="navbar-toggler-icon"></span> -->
      </button>
      <!-- hamburger end -->
    </div>
  </div>
</nav>
