<div class="d-flex justify-content-center mb-2 toast-position">
  <ngb-toast
    *ngIf="showWarning"
    [autohide]="false"
    (hidden)="showWarning = false"
    [ngClass]="{ 'text-light': true, 'bg-warning': true, 'w-75': true }"
  >
    <div class="d-flex justify-content-between align-items-center">
      You have unsaved flashcards
      <div>
        <button
          class="btn btn-sm btn-outline-dark"
          (click)="syncOfflineFlashcards()"
        >
          Sync
        </button>
        <button
          class="btn btn-close btn-close-white btn-light m-1 p-1"
          (click)="showWarning = false"
        ></button>
        <!-- <button
          class="btn btn-sm btn-outline-light ms-2"
          (click)="showWarning = false"
        >
          Cancel
        </button> -->
      </div>
    </div>
  </ngb-toast>
</div>

<div class="d-flex justify-content-center">
  <div class="d-flex flex-column w-100 w-lg-50 mw ms-2 me-2">
    <div>
      <h2 mat-dialog-title class="ms-0">Create New Flashcard</h2>
    </div>
    <div mat-dialog-content>
      <form class="" [formGroup]="flashcardForm">
        <div class="form-floating mb-1">
          <input
            class="form-control"
            id="floatingInput"
            formControlName="lexiText"
            name="lexiText"
            tabindex="0"
          />
          <label class="" for="floatingInput"> Word / Phrase</label>
        </div>
        <div class="d-flex mb-1">
          <select
            required
            (change)="onListSelected($event.target.value)"
            class="form-select me-1"
            formControlName="list"
          >
            <option
              *ngFor="let list of lists | async"
              [value]="list.favoriteId"
              required
              name="list"
            >
              {{ list.name }}
            </option>
          </select>
          <button
            class="btn btn-secondary text-nowrap"
            type="button"
            (click)="openCreateListDialog()"
          >
            New List
          </button>
        </div>
        <div id="listHelp" class="form-text">
          Select a list for the flashcard or create a new one.
        </div>
        <div class="form-floating mb-1">
          <input
            class="form-control"
            id="exampleSentence"
            formControlName="example"
            name="example"
          />
          <label class="" for="exampleSentence"> Example</label>
        </div>
        <div class="d-flex">
          <button
            class="btn btn-primary w-100"
            type="submit"
            (click)="createFlashcard()"
            tabindex="1"
            [disabled]="loading"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Create Flashcard
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
