<div class="col-md-6 offset-md-3 mt-5">
    <div class="alert alert-info">
        Please enter your e-mail address
    </div>
    <div class="card">
        <h4 class="card-header">Forgot Password</h4>
        <div class="card-body">
            <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">E-mail address</label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': f.username.touched && f.username.errors }"/>
                    <div *ngIf="f.username.touched && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">E-mail address is required</div>
                        <div *ngIf="f.username.errors.email">E-mail format is wrong</div>
                   </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <div *ngIf="response | async as res">
                    <div *ngIf="res?.success" class="alert alert-success mt-3 mb-0">{{res?.message}}</div>
                    <div *ngIf="!res?.success" class="alert alert-danger mt-3 mb-0">{{res?.message}}</div>
                </div>
            </form>
        </div>
    </div>
</div>