<div class="mb-3 me-3 p-3">
  <h3>{{ movieEpisodeDetail.title }}</h3>
  <h5>{{ movieEpisodeDetail.type }}</h5>

  <div *ngIf="movieEpisodeDetail?.type == 'series'; else elseBlock">
    <!-- <mat-form-field>
        <mat-label>Choose season</mat-label>
        <mat-select [(ngModel)]="selectedEpisode" (selectionChange)='onSeasonSelected($event)'>
            <mat-option *ngFor="let season of seasons" [value]="season">
                {{season}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->

    <div class="d-flex mb-1">
      <select
        required
        [(ngModel)]="selectedEpisode"
        (selectionChange)="onSeasonSelected($event)"
        class="form-select me-1"
      >
        <option
          *ngFor="let season of seasons"
          [value]="season"
          required
          name="list"
        >
          {{ season }}
        </option>
      </select>
    </div>
    <div class="overflow-auto episodes">
      <div *ngFor="let episode of episodes?.episodes">
        <div class="d-flex justify-content-between mt-3 episode-line">
          <span class="me-3">{{ episode.episode }} - {{ episode.title }}</span>
          <span>
            <button
              class="btn btn-primary"
              (click)="analyzeMovieOrEpisode(episode.imdbID)"
            >
              Analyse
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>

  <ng-template #elseBlock>
    <button
      class="btn btn-outline-primary"
      (click)="analyzeMovieOrEpisode(movieEpisodeDetail.imdbID)"
    >
      Analyse Movie
    </button>
  </ng-template>
</div>
