<mat-card class="example-card">
    <mat-card-header>
        <mat-card-title class="example-header">

            <h3 class="example-header-text">{{ example?.vocabulary }}
                <!-- <button mat-flat-button color="accent" (click)="addFavorite(searchResultItem.word)">
                    <mat-icon>add</mat-icon> Add to List
                </button> -->
            </h3>

            <h5 class="word-header-reference">{{ example?.reference }}
                <!-- <button mat-flat-button color="accent" (click)="addFavorite(searchResultItem.word)">
                    <mat-icon>add</mat-icon> Add to List
                </button> -->
            </h5>

        </mat-card-title>
    </mat-card-header>
    
    <mat-card-content>
            <div [innerHTML]="example?.text"></div>
    </mat-card-content>
</mat-card>
