import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, TimeoutError, concat, of, throwError } from 'rxjs';
import { FlashcardRequest } from '../models/flashcard.model';
import { GenericResponse } from '../models/generic-response';
import { catchError, map, retry, share, timeout } from 'rxjs/operators';
import { SyncFlashcardRequest } from '../models/offline-flashcard-request';

// Reference: https://daanstolp.nl/articles/2021/angular-pwa-2/
@Injectable({
  providedIn: 'root'
})
export class SyncFlashcardRequestService {
  HTTP_TIMEOUT_IN_MS = 5000;
  STORAGE_KEY = 'flashcardSyncTasks';

  constructor(private http: HttpClient) { }

  tryPostPayload(url: string, payload: String, headers: HttpHeaders): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(url, payload, { observe: 'response', headers: headers })
      .pipe(
        timeout(this.HTTP_TIMEOUT_IN_MS),
        //       retry(2),
        map(result => {
          const response: GenericResponse = new GenericResponse("Flashcard created", true);
          return response
        }),
        catchError(err => {
          return this.handleError(err, url, payload, headers)
        })
        //share()

      );
  }

  private handleError(err: String,
    url: string,
    payload: String,
    params: HttpHeaders): Observable<any> {

    if (err.startsWith("504 - Gateway Timeout") || err.startsWith("0 - Unknown Error")) {
      // A client-side or network error occurred. Handle it accordingly.

      this.addOrUpdateSyncTask(url, payload);

      const response: GenericResponse = new GenericResponse("Flashcard was cached to be sent later", true);
      return of(response);

    } else {
      console.log('A backend error occurred.');
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      return throwError(err);
    }
  }

  private offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return (
      err instanceof TimeoutError ||
      err.error instanceof ErrorEvent ||
      !navigator.onLine // A helper service that delegates to window.navigator.onLine
    );
  }

  private addOrUpdateSyncTask(url: string, payload: String): void {
    const tasks = this.getExistingSyncTasks();
    const headers = "{\"Content-Type\": \"application/json\"}"
    const syncTask = new SyncFlashcardRequest(url, payload, headers);
    tasks.push(syncTask);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(tasks));
  }

  public getExistingSyncTasks(): SyncFlashcardRequest[] {
    const serializedTasks = localStorage.getItem(this.STORAGE_KEY);

    return (serializedTasks)
      ? JSON.parse(serializedTasks)
      : [];
  }

  sync(): Observable<any> {
    const syncTasks = this.getExistingSyncTasks();[]
    const requests: Observable<any>[] = [];

    syncTasks.forEach((task: SyncFlashcardRequest) => {
      const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const obs$ = this.http.post(task.url, task.body, headers)
        .pipe(map(_ => task));

      requests.push(obs$);
    });

    const all$ = concat(...requests).pipe(share());

    all$.subscribe(task => {
      const index = syncTasks.findIndex(t => t.body == (task.body));
      syncTasks.splice(index, 1);
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(syncTasks));
      //this._syncTasks.next(syncTasks);
    });

    return all$
  }

}
