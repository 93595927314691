export class QuizRequest{
    private questionFindTheWord:boolean ;
    private questionFindTheDefinition:boolean ;
    private questionFindTheSynonym:boolean ;
    private questionFillInTheBlanks:boolean ;
    private answerType:string;
    private flashcards:number[];

    public getQuestionFindTheWord(): boolean {
        return this.questionFindTheWord;
    }

    public setQuestionFindTheWord(questionFindTheWord: boolean) {
        this.questionFindTheWord = questionFindTheWord;
    }

    public getQuestionFindTheDefinition(): boolean {
        return this.questionFindTheDefinition;
    }

    public setQuestionFindTheDefinition(questionFindTheDefinition: boolean) {
        this.questionFindTheDefinition = questionFindTheDefinition;
    }

    public getQuestionFindTheSynonym(): boolean {
        return this.questionFindTheSynonym;
    }

    public setQuestionFindTheSynonym(questionFindTheSynonym: boolean) {
        this.questionFindTheSynonym = questionFindTheSynonym;
    }

    public getQuestionFillInTheBlanks(): boolean {
        return this.questionFillInTheBlanks;
    }

    public setQuestionFillInTheBlanks(questionFillInTheBlanks: boolean) {
        this.questionFillInTheBlanks = questionFillInTheBlanks;
    }

    public getAnswerType(): string {
        return this.answerType;
    }

    public setAnswerType(answerType: string) {
        this.answerType = answerType;
    }

    public getFlashcards(): number[] {
        return this.flashcards;
    }

    public setFlashcards(flashcards: number[]) {
        this.flashcards = flashcards;
    }



    public QuizRequest() {
    }

    

}


export class QuizResponse{
   private questions: QuizQuestion[]
   public QuizResponse() {
}
}

export class QuizQuestion{
    private question:string
    private choices:string[]
    private answer:string
    private flashcardId:number

    public QuizQuestion() {
    }
    
    
}
