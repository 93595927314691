import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastLevel } from 'src/app/models/toast-model';
import { FavoriteService } from 'src/app/services/favorite.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-create-new-list-popup',
  templateUrl: './create-new-list-popup.component.html',
  styleUrls: ['./create-new-list-popup.component.scss'],
})
export class CreateNewListPopupComponent implements OnInit {
  listName: string;
  constructor(
    private favoriteService: FavoriteService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<CreateNewListPopupComponent>,

  ) {}

  ngOnInit(): void {}

  createList() {
    this.favoriteService.createNewList(this.listName).subscribe((result) => {
      if (result.success) {
        this.toastService.show(result.message,ToastLevel.success);
        localStorage.setItem('lastUsedList',result.createdPk)
        this.dialogRef.close();
      } else {
        this.toastService.show(result.message,ToastLevel.error);
      }
    },(error)=>{
      this.toastService.show(error,ToastLevel.error);
    });
  }
}
