export class GenericResponse{
	message:string;
	success:boolean;
	createdPk?:string;
	
	constructor(message:string,success:boolean,createdPk?:string){
        this.message=message;
        this.success=success;
		this.createdPk= createdPk;
	}

}