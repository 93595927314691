import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { Lexi } from '../models/movie-model';
import { GenericResponse } from '../models/generic-response';
import { FlashcardModel } from '../models/flashcard.model';
import { AnalysisDto, AnalysisLexiDto, AnalysisLineDto } from '../models/analysis-model';

@Injectable()
export class TextAnalysisService {
  constructor(private http: HttpClient) {}

  createNewTextAnalysis(
    title: string,
    rawText: string
  ): Observable<AnalysisDto> {
    let body: String = '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    body = body.concat('{"title":"', title, '","text":"', this.escapeChars(rawText), '"}');
    return this.http.post<AnalysisDto>('/analyzeText', body, {
      headers: headers,
    });
  }

  escapeChars(str) {
    return str
      .replace(/[\\]/g, '\\\\')
      .replace(/[\"]/g, '\\\"')
      .replace(/[\/]/g, '\\/')
      .replace(/[\b]/g, '\\b')
      .replace(/[\f]/g, '\\f')
      .replace(/[\n]/g, '\\n')
      .replace(/[\r]/g, '\\r')
      .replace(/[\t]/g, '\\t');
  };

  getAnalysisList(): Observable<AnalysisDto[]> {
    return this.http.get<AnalysisDto[]>('/getCustomerAnalysisList', {});
  }

  saveAnalysis(analysis: AnalysisDto): Observable<GenericResponse> {
    console.log(analysis);
    let body: String = '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // body = body.concat('{"analysisDto":"', JSON.stringify(analysis)+ '"}');
    return this.http.post<GenericResponse>(
      '/updateAnalysis',
      JSON.stringify(analysis),
      {
        headers: headers,
      }
    );
  }

  getFullAnalysis(pk: number): Observable<AnalysisDto> {
    if (pk) {
      const url: string = '/getAnalysisDetails?pk=' + pk;
      return this.http.get<AnalysisDto>(url, {});
    }
    return null;
  }

  getPartialAnalysis(pk: number,section:number): Observable<AnalysisLexiDto[]> {
    if (pk) {
      const url: string = '/getPartialAnalysis?pk=' + pk + '&section='+section;
      return this.http.get<AnalysisLexiDto[]>(url, {});
    }
    return null;
  }

  getAnalysisLines(pk: number,line:number): Observable<AnalysisLineDto[]> {
    if (pk) {
      const url: string = '/getCustomerAnalysisLines?pk=' + pk + '&lineNo='+line;
      return this.http.get<AnalysisLineDto[]>(url, {});
    }
    return null;
  }

  deleteAnalysis(pk: number): Observable<GenericResponse> {
    if (pk) {
      const url: string = '/deleteCustomerAnalysis?pk=' + pk ;
      return this.http.delete<GenericResponse>(url, {});
    }
    return null;
  }
}
