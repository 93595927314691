<div *ngIf="(inputAnalysis) as result">

    <mat-tab-group mat-align-tabs="center" animationDuration="0ms" [(selectedIndex)]="selectedIndexBinding"
        (selectedIndexChange)="onTabChanged($event)">
        <mat-tab *ngFor="let section of tabs;index as tabNo">
            <ng-template mat-tab-label>
                <span class="tab-heading">{{section}}
                    <!-- <span *ngIf="section">({{section.length}})</span> -->
                </span>
            </ng-template>
            <!--  -->
            <ng-template matTabContent>

                <div class="container mt-3">
                    <mat-progress-bar *ngIf="fetchingData" mode="indeterminate"></mat-progress-bar>

                    <div class="row">
                        <div class="col-4 d-flex align-items-center justify-content-center">
                            <div *ngIf="analysisParts[tabNo] | async  as part">{{part?.length}}
                                {{tabs[tabNo]}} Found </div>
                        </div>
                        <div class="col-8 d-flex align-items-center justify-content-center" *ngIf="selectedIndexBinding && selectedIndexBinding==2 && analysisParts[tabNo] | async">
                            <div class="col d-flex align-items-center justify-content-center">
                                {{(analysisParts[tabNo] | async | filterlevel:level)?.length}} Lines Displayed
                            </div>
                            <div class="col">
                                Level:
                                <mat-slider class="example-margin" [displayWith]="formatLabel" [max]=5 [min]=1 [step]=1 [thumbLabel]=true
                                    [tickInterval]=1 [(ngModel)]="level">
                                </mat-slider>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid p-2 d-flex flex-wrap align-items-start">
                        <div *ngFor="let item of  analysisParts[tabNo] | async | filterlevel:level; index as i"
                            class="card m-1" style="width: 18rem;">
                            <div class="card-body">
                                <h5 class="card-title">{{item.lexiText | indefinitepronoun}}
                                    <span [hidden]="!isLexiInFlashcards(item.lexiText)">
                                        <mat-icon color="warn" class="ml-1"
                                            ngbTooltip="You already have a flashcard for this item" container="body">
                                            favorite</mat-icon>
                                    </span>
                                </h5>
                                <mat-card-subtitle>{{item.relatedLines.length}} Lines</mat-card-subtitle>
                                <p class="card-text">{{item.relatedLines[0].lineText | longtext}} &nbsp; </p>
                                <button class="btn mr-2 btn-sm btn-outline-primary" id="lexiDetails"
                                    (click)="openLexiDetailsDialog(item)">Details</button>
                                <button class="btn mr-2 btn-sm btn-outline-secondary" id="lexiActions"
                                    [matMenuTriggerFor]="menu" #menuTrigger>Actions</button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="openFlashcardDialog(item)">
                                        <mat-icon>collections_bookmark</mat-icon>Create Flashcard
                                    </button>
                                    <button mat-menu-item (click)="addToKnownWords(item.lexiText)">
                                        <mat-icon>disabled_visible</mat-icon>Add to Known Words
                                    </button>
                                    <button mat-menu-item (click)="openFlashcardsListDialog(item)"
                                        [hidden]="!isLexiInFlashcards(item.lexiText)">
                                        <mat-icon>favorite</mat-icon>See Flashcards
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab >
            <ng-template mat-tab-label>
                <!-- <mat-icon class="example-tab-icon">subtitles</mat-icon> -->
                <span class="tab-heading">Tagged View</span>
            </ng-template>
            <mat-progress-bar *ngIf="fetchingData" mode="indeterminate"></mat-progress-bar>

            <div *ngFor="let line of taggedLines | async">
                <mat-card class="word-card">
                    <div>
                        {{line.lineId}})&nbsp;{{line.lineText}}
                    </div>
                    <div *ngIf="line.lineIdioms?.length >0" class="tag-row">
                        <b>Idioms: </b>
                        <span *ngFor="let idiom of line.lineIdioms"> <span class="idiom" (click)="openLexiDetailsDialog(undefined,idiom)">{{idiom |
                                indefinitepronoun}}</span> &nbsp;</span>
                    </div>
                    <div *ngIf="line.linePhrasals?.length >0" class="tag-row">
                        <b>Phrasal verbs: </b>
                        <span *ngFor="let phrasal of line.linePhrasals"> <span class="phrasal" (click)="openLexiDetailsDialog(undefined,phrasal)">{{phrasal |
                                indefinitepronoun}}</span> &nbsp;</span>
                    </div>
                    <div *ngIf="line.lineSingleWords?.length >0" class="tag-row">
                        <b>Single words: </b>
                        <span *ngFor="let single of line.lineSingleWords "> <span class="single-word" (click)="openLexiDetailsDialog(undefined,single)">{{single}}</span>
                            &nbsp;</span>
                    </div>
                    <mat-divider></mat-divider>

                </mat-card>
            </div>
        </mat-tab> 

    </mat-tab-group>
    <app-scroll-to-top></app-scroll-to-top>
</div>